body {
  background-color: #000 !important;
}

p {
  margin-bottom: 0px !important;
}

.css-equal-content,
.css-equal-heights {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.gallery-property ul li a,
.cart-section .product-group .product-content .social-links a,
.shop-page .product-custom .profile-widget .doc-img-view ul li a,
.blog-banner .blog-detailset,
.blog-banner,
.bloglistleft-widget .post-author,
.bloggrid-sidebar .post-author,
.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item li .post-author,
.blog-section .blog-content .meta-item,
.blog-section .blog-content .date-icon .post-author,
.blog-section .blog-content .date-icon,
.our-team-section .our-team .team-prof .footer-social-links ul li a,
.our-team-section .our-team .team-prof .footer-social-links ul,
.contact-info-sec .contact-info-details span,
.details-wrap.agency .detail-user-img,
.service-area li::before,
.agent-list.agency .agent-img,
.login-wrapper,
.modal-succeess .success-popup h4,
.modal-succeess .success-popup,
.booking-details .payment-icon ul li span,
.booking-details .payment-icon ul li,
.success-div span,
.grid-list-view ul li a,
.connect-us li a,
.sidebar-card .contact-btn.contact-btn-new a,
.collapse-card .sample-video .play-icon,
.compare-head .compare-action a,
.grid-pagination .pagination li a,
.blog-card .blog-content .property-category a span,
.product-custom .favourite,
.testimonial-card .user-icon,
.single-property-card .buy-property .arrow,
.cities-list-sec .city-list .arrow-overlay a,
.property-card,
.howit-work-card .work-card-icon span,
.howit-work-card,
.section-heading,
.banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child a,
.banner-search .banner-tab-property .banner-property-info,
.banner-search .banner-tab .nav-tabs .nav-item .nav-link,
.serve-form .form-group .button-notific,
.serve-form .form-group,
.error-page .main-wrapper,
.error-page,
.header .header-navbar-rht li .btn,
.header .main-menu-wrapper .main-nav li .btn.sign-btn,
.header .header-top .template-ad,
.login-body .footer-social-links .nav li a,
.login-body .footer-social-links .nav,
.social-links ul li a,
.avatar .avatar-title,
.btn-lightred,
.btn-secondary,
.btn-primary,
.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.login-body .footer-social-widget .nav-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.details-wrap .detail-user-wrap .user-info-wrap,
.agent-list .agent-content .agent-info,
.single-property-card .buy-property,
.product-custom .feature-rating,
.header .header-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.sort-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item,
.invoice-section .card .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.cart-section .product-group .detail-gallery .new-featured span {
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.cart-section .product-group .product-content .social-links a,
.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.shop-page #colors-available .colors-themes,
.blog-section .rightsidebar .card h4,
.profile-widget.rent-list-view,
.inside-collapse-card ul li,
.collapse-card .map iframe,
.agent-list,
.property-category .user-info .user-name,
.breadcrumb .bread-crumb-head .breadcrumb-list ul,
.header .header-navbar-rht .has-arrow .user-header,
.header .main-menu-wrapper .main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.property-submit .btn,
.details-wrap .detail-user-wrap .user-info-wrap .detail-action .btn,
.agent-list .agent-content .agent-info h5,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.blog-banner .post-author,
.our-agent,
.details-wrap .detail-user-wrap .detail-user-info p,
.details-wrap .detail-user-wrap,
.agent-list.agency .agent-content .agency-user .agency-user-info p,
.agency-user,
.agent-card.agency .agency-user .agency-user-info p,
.agent-card.agency .agency-user,
.advanced-search .review-form-btn,
.booking-details-btn,
.booking-details-price ul li,
.booking-details ul,
.arrival-div ul,
.details-div,
.more a,
.less a,
.price-filter .caption,
.profile-widget.rent-list-view .pro-content .list-head p,
.grid-list-view ul,
.grid-head,
.calender ul li,
.calender ul,
.list-details li,
.connect-us,
.user-active .user-name .rating.star-rate,
.user-active,
.sidebar-card .contact-btn a,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info .customer-name,
.collapse-card .review-card .customer-review .customer-info,
.collapse-card ul.amenities-list li,
.latest-update .other-pages li a,
.latest-update .other-pages,
.page-title p,
.rating .rating-count,
.rating,
.agent-card .agent-content p,
.property-category .user-info,
.pro-content .details li,
.price-card .price-features ul li,
.price-section .pricing-tab,
.privacy-section .terms-policy ul li,
.header .header-navbar-rht,
.footer-bottom-content .company-logo,
.footer-bottom-content,
.footer-widget-list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.our-agent .agent-name p,
.list-details.con-list li span,
.agent-list .agent-content .agent-info .rating,
.custom_check,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-navbar-rht li.new-property-btn a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sort-result,
.latest-update .other-pages,
.pro-content .details,
.header .header-nav {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.inside-collapse-card ul li {
  -ms-flex-direction: column;
  flex-direction: column;
}

.cart-section .product-group .detail-gallery .zoom-icon,
.our-team-section .our-team .team-prof .footer-social-links ul,
.breadcrumb .bread-crumb-head .breadcrumb-list ul {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.profile-widget.rent-list-view .pro-content .details {
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.calender ul,
.list-details li,
.connect-us,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info,
.pro-content .details,
.price-section .pricing-tab,
.header .menu-header,
.footer-bottom-content {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.inside-collapse-card ul li {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.blog-section .rightsidebar .card h4,
.blog-section .blog-content .viewlink,
.header .menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*-----------------
	1. Typography
-----------------------*/
h1 {
  font-weight: 700;
  font-size: 36px;
}

@media (max-width: 991.98px) {
  h1 {
    font-size: 32px;
  }
}

@media (max-width: 767.78px) {
  h1 {
    font-size: 28px;
  }
}

h2 {
  font-weight: 700;
  font-size: 32px;
}

@media (max-width: 991.98px) {
  h2 {
    font-size: 28px;
  }
}

@media (max-width: 767.78px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-weight: 700;
  font-size: 28px;
}

@media (max-width: 767.78px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-weight: 600;
  font-size: 24px;
}

@media (max-width: 767.78px) {
  h4 {
    font-size: 16px;
  }
}

h5 {
  font-weight: 700;
  font-size: 20px;
}

@media (max-width: 991.98px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-weight: 700;
  font-size: 18px;
}

@media (max-width: 991.98px) {
  h6 {
    font-size: 16px;
  }
}

/*-----------------
	2. Colors
-----------------------*/
.primary {
  background-color: #ebbb67;
}

.secondary {
  background-color: #1E1D85;
}

.bg-red {
  background-color: #FD3358 !important;
}

.bg-green {
  background-color: #0DCA95 !important;
}

.text-primary {
  color: #ebbb67 !important;
}

/*-----------------
	3. General
-----------------------*/
body {
  background-color: #FFF;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  color: #8A909A;
  height: 100%;
  overflow-x: hidden;
  line-height: 1.5;
}

@media (max-width: 767.78px) {
  body {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}



a:focus {
  outline: 0;
}

p {
  margin-bottom: 20px;
}



@media (max-width: 767.78px) {
  p {
    font-size: 15px;
  }
}

strong {
  font-weight: 700;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0E0E46;
}

/*-----------------
	4. Grid
-----------------------*/
.css-equal-heights,
.css-equal-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.gallery-property ul li a,
.cart-section .product-group .product-content .social-links a,
.shop-page .product-custom .profile-widget .doc-img-view ul li a,
.blog-banner .blog-detailset,
.blog-banner,
.bloglistleft-widget .post-author,
.bloggrid-sidebar .post-author,
.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item li .post-author,
.blog-section .blog-content .meta-item,
.blog-section .blog-content .date-icon .post-author,
.blog-section .blog-content .date-icon,
.our-team-section .our-team .team-prof .footer-social-links ul li a,
.our-team-section .our-team .team-prof .footer-social-links ul,
.contact-info-sec .contact-info-details span,
.details-wrap.agency .detail-user-img,
.service-area li::before,
.agent-list.agency .agent-img,
.login-wrapper,
.modal-succeess .success-popup h4,
.modal-succeess .success-popup,
.booking-details .payment-icon ul li span,
.booking-details .payment-icon ul li,
.success-div span,
.grid-list-view ul li a,
.connect-us li a,
.sidebar-card .contact-btn.contact-btn-new a,
.collapse-card .sample-video .play-icon,
.compare-head .compare-action a,
.grid-pagination .pagination li a,
.blog-card .blog-content .property-category a span,
.product-custom .favourite,
.testimonial-card .user-icon,
.single-property-card .buy-property .arrow,
.cities-list-sec .city-list .arrow-overlay a,
.property-card,
.howit-work-card .work-card-icon span,
.howit-work-card,
.section-heading,
.banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child a,
.banner-search .banner-tab-property .banner-property-info,
.banner-search .banner-tab .nav-tabs .nav-item .nav-link,
.serve-form .form-group .button-notific,
.serve-form .form-group,
.error-page .main-wrapper,
.error-page,
.header .header-navbar-rht li .btn,
.header .main-menu-wrapper .main-nav li .btn.sign-btn,
.header .header-top .template-ad,
.login-body .footer-social-links .nav li a,
.login-body .footer-social-links .nav,
.social-links ul li a,
.avatar .avatar-title,
.btn-lightred,
.btn-secondary,
.btn-primary,
.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.login-body .footer-social-widget .nav-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.details-wrap .detail-user-wrap .user-info-wrap,
.agent-list .agent-content .agent-info,
.single-property-card .buy-property,
.product-custom .feature-rating,
.header .header-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.sort-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item,
.invoice-section .card .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.cart-section .product-group .detail-gallery .new-featured span {
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.cart-section .product-group .product-content .social-links a,
.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.shop-page #colors-available .colors-themes,
.blog-section .rightsidebar .card h4,
.profile-widget.rent-list-view,
.inside-collapse-card ul li,
.collapse-card .map iframe,
.agent-list,
.property-category .user-info .user-name,
.breadcrumb .bread-crumb-head .breadcrumb-list ul,
.header .header-navbar-rht .has-arrow .user-header,
.header .main-menu-wrapper .main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.property-submit .btn,
.details-wrap .detail-user-wrap .user-info-wrap .detail-action .btn,
.agent-list .agent-content .agent-info h5,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.blog-banner .post-author,
.our-agent,
.details-wrap .detail-user-wrap .detail-user-info p,
.details-wrap .detail-user-wrap,
.agent-list.agency .agent-content .agency-user .agency-user-info p,
.agency-user,
.agent-card.agency .agency-user .agency-user-info p,
.agent-card.agency .agency-user,
.advanced-search .review-form-btn,
.booking-details-btn,
.booking-details-price ul li,
.booking-details ul,
.arrival-div ul,
.details-div,
.more a,
.less a,
.price-filter .caption,
.profile-widget.rent-list-view .pro-content .list-head p,
.grid-list-view ul,
.grid-head,
.calender ul li,
.calender ul,
.list-details li,
.connect-us,
.user-active .user-name .rating.star-rate,
.user-active,
.sidebar-card .contact-btn a,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info .customer-name,
.collapse-card .review-card .customer-review .customer-info,
.collapse-card ul.amenities-list li,
.latest-update .other-pages li a,
.latest-update .other-pages,
.page-title p,
.rating .rating-count,
.rating,
.agent-card .agent-content p,
.property-category .user-info,
.pro-content .details li,
.price-card .price-features ul li,
.price-section .pricing-tab,
.privacy-section .terms-policy ul li,
.header .header-navbar-rht,
.footer-bottom-content .company-logo,
.footer-bottom-content,
.footer-widget-list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.our-agent .agent-name p,
.list-details.con-list li span,
.agent-list .agent-content .agent-info .rating,
.custom_check,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-navbar-rht li.new-property-btn a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sort-result,
.latest-update .other-pages,
.pro-content .details,
.header .header-nav {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.inside-collapse-card ul li {
  -ms-flex-direction: column;
  flex-direction: column;
}

.cart-section .product-group .detail-gallery .zoom-icon,
.our-team-section .our-team .team-prof .footer-social-links ul,
.breadcrumb .bread-crumb-head .breadcrumb-list ul {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.profile-widget.rent-list-view .pro-content .details {
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.calender ul,
.list-details li,
.connect-us,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info,
.pro-content .details,
.price-section .pricing-tab,
.header .menu-header,
.footer-bottom-content {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.inside-collapse-card ul li {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.blog-section .rightsidebar .card h4,
.blog-section .blog-content .viewlink,
.header .menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.col {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  position: relative;
  min-height: 1px;
}

@media (min-width: 320px) {
  .col.col-xs-12 {
    width: 100%;
  }

  .col.col-xs-11 {
    width: 91.66666667%;
  }

  .col.col-xs-10 {
    width: 83.33333333%;
  }

  .col.col-xs-9 {
    width: 75%;
  }

  .col.col-xs-8 {
    width: 66.66666667%;
  }

  .col.col-xs-7 {
    width: 58.33333333%;
  }

  .col.col-xs-6 {
    width: 50%;
  }

  .col.col-xs-5 {
    width: 41.66666667%;
  }

  .col.col-xs-4 {
    width: 33.33333333%;
  }

  .col.col-xs-3 {
    width: 25%;
  }

  .col.col-xs-2 {
    width: 16.66666667%;
  }

  .col.col-xs-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 568px) {
  .col.col-sm-12 {
    width: 100%;
  }

  .col.col-sm-11 {
    width: 91.66666667%;
  }

  .col.col-sm-10 {
    width: 83.33333333%;
  }

  .col.col-sm-9 {
    width: 75%;
  }

  .col.col-sm-8 {
    width: 66.66666667%;
  }

  .col.col-sm-7 {
    width: 58.33333333%;
  }

  .col.col-sm-6 {
    width: 50%;
  }

  .col.col-sm-5 {
    width: 41.66666667%;
  }

  .col.col-sm-4 {
    width: 33.33333333%;
  }

  .col.col-sm-3 {
    width: 25%;
  }

  .col.col-sm-2 {
    width: 16.66666667%;
  }

  .col.col-sm-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 768px) {
  .col.col-md-12 {
    width: 100%;
  }

  .col.col-md-11 {
    width: 91.66666667%;
  }

  .col.col-md-10 {
    width: 83.33333333%;
  }

  .col.col-md-9 {
    width: 75%;
  }

  .col.col-md-8 {
    width: 66.66666667%;
  }

  .col.col-md-7 {
    width: 58.33333333%;
  }

  .col.col-md-6 {
    width: 50%;
  }

  .col.col-md-5 {
    width: 41.66666667%;
  }

  .col.col-md-4 {
    width: 33.33333333%;
  }

  .col.col-md-3 {
    width: 25%;
  }

  .col.col-md-2 {
    width: 16.66666667%;
  }

  .col.col-md-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 1024px) {
  .col.col-lg-12 {
    width: 100%;
  }

  .col.col-lg-11 {
    width: 91.66666667%;
  }

  .col.col-lg-10 {
    width: 83.33333333%;
  }

  .col.col-lg-9 {
    width: 75%;
  }

  .col.col-lg-8 {
    width: 66.66666667%;
  }

  .col.col-lg-7 {
    width: 58.33333333%;
  }

  .col.col-lg-6 {
    width: 50%;
  }

  .col.col-lg-5 {
    width: 41.66666667%;
  }

  .col.col-lg-4 {
    width: 33.33333333%;
  }

  .col.col-lg-3 {
    width: 25%;
  }

  .col.col-lg-2 {
    width: 16.66666667%;
  }

  .col.col-lg-1 {
    width: 8.33333333%;
  }
}

/*-----------------
	5. Buttons
-----------------------*/
.btn-primary {
  font-weight: 700;
  font-size: 15px;
  color: #FFF;
  background-color: #ebbb67;
  border: 1px solid #ebbb67;
  box-shadow: inset 0 0 0 0 #F6F6F9;
  border-radius: 10px;
  padding: 12px 15px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 767.78px) {
  .btn-primary {
    font-size: 14px;
    padding: 10px 15px;
  }
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
  color: #8A909A;
  background-color: #F6F6F9;
  border-color: #F6F6F9;
  box-shadow: inset 0 50px 0 0 #F6F6F9;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.btn-secondary {
  font-weight: 700;
  font-size: 15px;
  color: #8A909A;
  background-color: #F6F6F9;
  border: 1px solid #F6F6F9;
  box-shadow: inset 0 0 0 0 #ebbb67;
  border-radius: 10px;
  padding: 12px 15px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 767.78px) {
  .btn-secondary {
    font-size: 14px;
    padding: 10px 15px;
  }
}

.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary.active {
  color: #FFF;
  background-color: #ebbb67;
  border-color: #ebbb67;
  box-shadow: inset 0 50px 0 0 #ebbb67;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.btn-lightred {
  font-weight: 700;
  font-size: 15px;
  font-weight: 700;
  color: #FFF;
  background: #FD3358;
  border: 1px solid #FD3358;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
  box-shadow: inset 0 0 0 0 #FFF;
  border-radius: 10px;
  padding: 12px 15px;
}

.btn-lightred:focus,
.btn-lightred:hover,
.btn-lightred:active,
.btn-lightred.active {
  color: #FD3358;
  background-color: #FFF;
  border-color: #FD3358;
  box-shadow: inset 0 50px 0 0 #FFF;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: inherit;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #FFF;
  border-color: #ebbb67;
  color: #ebbb67;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #FFF;
  border-color: #ebbb67;
  color: #ebbb67;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  background-color: #FFF;
  border-color: #1E1D85;
  color: #1E1D85;
}

/*-----------------
	6. Bootstrap Class
-----------------------*/
.form-group {
  margin-bottom: 20px;
}

.form-group .forgot-link {
  color: #FD3358;
  font-weight: 700;
  font-size: 15px;
  float: right;
}

.form-group .forgot-link:hover {
  color: #ebbb67;
}

.form-group .pass-group {
  position: relative;
}

.form-control {
  font-size: 14px;
  background: #F6F6F9;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  color: #0E0E46;
  min-height: 50px;
  margin: 0;
  padding: 9px 15px;
}

@media (max-width: 991.98px) {
  .form-control {
    min-height: 40px;
  }
}

.form-control::-webkit-input-placeholder {
  font-size: 14px;
  color: #8A909A;
  font-weight: 400;
}

.form-control::-moz-placeholder {
  font-size: 14px;
  color: #8A909A;
  font-weight: 400;
}

.form-control:-ms-input-placeholder {
  font-size: 14px;
  color: #8A909A;
  font-weight: 400;
}

.form-control::-ms-input-placeholder {
  font-size: 14px;
  color: #8A909A;
  font-weight: 400;
}

.form-control::placeholder {
  font-size: 14px;
  color: #8A909A;
  font-weight: 400;
}

.form-control:focus {
  box-shadow: none;
  outline: 0 none;
  border-color: #EEEEEE;
}

.card {
  border: 1px solid #EEEEEE;
  background: #FFF;
  border-radius: 0;
  margin-bottom: 24px;
  box-shadow: 0px 4px 24px rgba(234, 234, 234, 0.3);
}

.card .card-header {
  background-color: transparent;
  border: 0;
  padding: 24px;
}

.card .card-header .card-title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.card .card-body {
  padding: 24px;
}

label {
  color: #0E0E46;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

label span {
  color: #FF0000;
}

.modal-backdrop {
  background-color: #15152F;
  opacity: 0.95;
}

.modal-backdrop.show {
  opacity: 0.95;
}

.bootstrap-datetimepicker-widget table td.active {
  background-color: #ebbb67;
}

.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #ebbb67;
}

.card {
  color: inherit;
}

.card-title {
  color: #0E0E46;
}

/*-----------------
	7. Switch
-----------------------*/
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.check:checked+.checktoggle {
  background-color: #10B990;
  border: 1px solid #10B990;
}

.check:checked+.checktoggle:after {
  background-color: #FFF;
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
  -webkit-transform: translate(calc(-100% - 5px), -50%);
  -ms-transform: translate(calc(-100% - 5px), -50%);
}

.checktoggle {
  background-color: #FF0000;
  border: 1px solid #FF0000;
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 42px;
}

.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  -webkit-transform: translate(5px, -50%);
  -ms-transform: translate(5px, -50%);
  width: 14px;
  height: 14px;
  background-color: #FFF;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.status-toggle .check:checked+.checktoggle {
  background-color: #10B990;
  border: 1px solid #10B990;
}

.status-toggle .check:checked+.checktoggle:after {
  transform: translate(calc(-100% - 2px), -50%);
  -webkit-transform: translate(calc(-100% - 2px), -50%);
  -ms-transform: translate(calc(-100% - 2px), -50%);
}

.status-toggle .checktoggle {
  width: 41px;
  height: 21px;
}

.status-toggle .checktoggle::after {
  width: 17px;
  height: 17px;
  transform: translate(2px, -50%);
  -webkit-transform: translate(2px, -50%);
  -ms-transform: translate(2px, -50%);
}

.status-tog .checktoggle {
  height: 28px;
  width: 50px;
  border-radius: 999px;
  background-color: #7D858F;
  border-color: #7D858F;
}

.status-tog .checktoggle:before {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  /* background-image: url(../img/icons/check.html); */
  background-repeat: no-repeat;
  background-size: 11px 20px;
  width: 20px;
  height: 20px;
  transform: translate(3px, -50%);
}

.status-tog .checktoggle:after {
  width: 24px;
  height: 24px;
  /* background-image: url(../img/icons/x-icon.html); */
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(1px, -50%);
  background-position: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.status-tog .check:checked+.checktoggle:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  /* background-image: url(../img/icons/x-icon.html); */
  background-repeat: no-repeat;
  background-size: 13px 21px;
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}

.status-tog .check:checked+.checktoggle:after {
  /* background-image: url(../img/icons/check.html); */
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(calc(-100% - 1px), -50%);
  background-position: center;
}

.bootstrap-tagsinput {
  display: block;
  border-radius: 5px;
  background: #FFF;
  border: 1px solid #F5F5F5;
  height: 46px;
  margin: 0;
  padding: 4px 10px 0 10px;
  width: 100%;
  box-shadow: none;
  overflow-y: auto;
}

.bootstrap-tagsinput .tag {
  margin-right: 5px;
  margin-bottom: 2px;
  color: #A9A9A9;
  background: #0E0E46 !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
}

.bootstrap-tagsinput .tag:hover {
  color: #A9A9A9;
}

.bootstrap-tagsinput .tag [data-role=remove] {
  position: relative;
  left: 7px;
  cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role=remove]:after {
  content: "x";
  margin: 0;
  padding: 0px 2px;
}

.bootstrap-tagsinput input {
  line-height: 36px;
  border: 1px solid transparent;
  background: transparent;
}

.bootstrap-tagsinput input:focus-visible {
  border: 1px solid transparent;
}

/*-----------------
	8. Select
-----------------------*/
.select2-dropdown {
  border: 1px solid #EEEEEE;
}

.select2-container .select2-selection--single {
  border: 1px solid #F6F6F9;
  background: #F6F6F9;
  height: 50px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  color: #0E0E46;
  padding-right: 15px;
  padding-left: 15px;
  outline: none;
}

.select2-hidden-accessible {
  width: 100%;
  background: #F6F6F9;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  border-radius: 10px;
  min-height: 60px;
}

.select2-hidden-accessible option {
  font-size: 14px;
  padding-right: 15px;
  padding-left: 15px;
}

.select2-container--default.select2-container--focus .select2-selection--single .select2-selection__rendered {
  color: #0E0E46;
}

.select2-container--default .select2-selection--single {
  border-radius: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
  right: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #8A909A transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  margin-left: -5px;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #8A909A;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
}

.select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
  top: 7px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #8A909A;
  border-width: 0 6px 6px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ebbb67;
  color: #FFF;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #F5F5F5;
  min-height: 50px;
}

.select2-results__option {
  font-size: 14px;
  padding-right: 15px;
  padding-left: 15px;
}

.cart-select .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 45px;
}

.cart-select .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
}

.cart-select .select2-container--default .select2-selection--multiple {
  min-height: 45px;
}

.cart-select .select2-container .select2-selection--single {
  height: 45px;
}

/*-----------------
	9. Avatar
-----------------------*/
.avatar {
  background-color: transparent;
  color: #FFF;
  display: inline-block;
  font-weight: 500;
  height: 34px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 34px;
  position: relative;
  white-space: nowrap;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.avatar.avatar-xs {
  width: 24px;
  height: 24px;
}

.avatar>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.avatar .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #0185FF;
  color: #FFF;
}

.avatar.avatar-online::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #FFF;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #10B990;
}

.avatar.avatar-offline::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #FFF;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #FF0000;
}

.avatar.avatar-away::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #FFF;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #1E1D85;
}

.avatar .border {
  border-width: 3px !important;
}

.avatar .rounded {
  border-radius: 6px !important;
}

.avatar .avatar-title {
  font-size: 18px;
}

.avatar .avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.avatar .avatar-xs .border {
  border-width: 2px !important;
}

.avatar .avatar-xs .rounded {
  border-radius: 4px !important;
}

.avatar .avatar-xs .avatar-title {
  font-size: 12px;
}

.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 27px;
  height: 27px;
  min-width: 27px;
}

.avatar-sm .border {
  border-width: 3px !important;
}

.avatar-sm .rounded {
  border-radius: 4px !important;
}

.avatar-sm .avatar-title {
  font-size: 15px;
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.avatar-lg .border {
  border-width: 3px !important;
}

.avatar-lg .rounded {
  border-radius: 8px !important;
}

.avatar-lg .avatar-title {
  font-size: 24px;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xl .border {
  border-width: 4px !important;
}

.avatar-xl .rounded {
  border-radius: 8px !important;
}

.avatar-xl .avatar-title {
  font-size: 28px;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

@media (min-width: 767.78px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-xxl .border {
  border-width: 6px !important;
}

@media (min-width: 767.78px) {
  .avatar-xxl .border {
    border-width: 4px !important;
  }
}

.avatar-xxl .rounded {
  border-radius: 8px !important;
}

@media (min-width: 768px) {
  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }
}

.avatar-xxl .avatar-title {
  font-size: 30px;
}

@media (min-width: 768px) {
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}

@media (min-width: 768px) {

  .avatar-xxl.avatar-away::before,
  .avatar-xxl.avatar-offline::before,
  .avatar-xxl.avatar-online::before {
    border-width: 4px;
  }
}

.avatar-group .avatar+.avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

.avatar-group .avatar-xs+.avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-group .avatar-sm+.avatar-sm {
  margin-left: -0.625rem;
}

.avatar-group .avatar-lg+.avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

/*-----------------	
    10. Content
-----------------------*/
.content {
  padding: 60px 0;
}

.content.inner-content {
  background: #F7F6FF;
}

@media (max-width: 991.98px) {
  .content {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .content {
    padding: 40px 0;
  }
}

.content.feature-property-sec {
  background-color: transparent;
  padding: 60px 0;
}

@media (max-width: 991.98px) {
  .content.feature-property-sec {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .content.feature-property-sec {
    padding: 40px 0;
  }
}

.main-wrapper {
  overflow: clip;
  width: 100%;
  float: left;
}

* {
  text-decoration: none !important;
  box-sizing: border-box;
}

.header {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  /* background-color: #FFF; */
  position: sticky;
  top: 0;
  left: 0;
}

.header .header-top {
  text-align: center;
  background: #F6F4FF;
  margin: 0;
  padding: 10px;
}

.header .header-top .template-ad h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.header .header-top .template-ad h5 span {
  color: #ebbb67;
}

.header .header-top .template-ad img {
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  .header .header-top {
    display: none;
  }
}

.header.min-header {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
}

.header.header-fixed {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 9999;
  background: #FFF;
  box-shadow: inherit;
}

.header .header-nav {
  background-color: transparent;
  border: 0;
  position: relative;
  margin: 0;
  padding: 0 30px;
  height: 70px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  justify-content: left;
}

@media (max-width: 1199.98px) {
  .header .header-nav {
    margin: 0;
    padding: 0 25px;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav {
    margin: 0;
    padding: 0 20px;
  }
}

@media (max-width: 767.78px) {
  .header .header-nav {
    margin: 0;
    padding: 0 15px;
  }
}

@media (max-width: 991.98px) {
  .header .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (max-width: 767.78px) {
  .header .navbar-header {
    width: 100%;
  }
}

.header .navbar-header #mobile_btn {
  display: none;
  margin-right: 30px;
}

@media (max-width: 991.98px) {
  .header .navbar-header #mobile_btn {
    display: inline-block;
    position: relative;
    top: 3px;
  }
}

@media (max-width: 767.78px) {
  .header .navbar-header #mobile_btn {
    margin-right: 0;
  }
}

.header .navbar-header #mobile_btn .bar-icon {
  display: inline-block;
  width: 31px;
}

.header .navbar-header #mobile_btn .bar-icon span {
  background-color: #ebbb67;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  position: relative;
}

.header .navbar-header #mobile_btn .bar-icon span:nth-child(2) {
  width: 16px;
}

.header .navbar-header #mobile_btn .bar-icon span:nth-child(3) {
  margin-bottom: 0;
}

.header .main-menu-wrapper {
  margin: 0;
  padding: 0;
}

.header .main-menu-wrapper .main-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav ul {
    /* background-color: #ebbb67; */
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
}

.header .main-menu-wrapper .main-nav li .btn {
  color: #FFF;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 8px;
  border: 1px solid #ebbb67;
  min-width: 140px;
}

.header .main-menu-wrapper .main-nav li .btn:hover {
  color: #ebbb67;
  border: 1px solid #ebbb67;
}

.header .main-menu-wrapper .main-nav li .btn i {
  font-size: 15px;
  margin-right: 5px;
  margin-left: 0;
}

.header .main-menu-wrapper .main-nav li .btn.sign-btn {
  background-color: #FFF;
  border: 1px solid #FFF;
  color: #041B29;
  box-shadow: inset 0 0 0 0 #ebbb67;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header .main-menu-wrapper .main-nav li .btn.sign-btn:hover {
  color: #FFF;
  background-color: #ebbb67;
  border: 1px solid #ebbb67;
  box-shadow: inset 0 0 0 50px #ebbb67;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.header .main-menu-wrapper .main-nav li .btn.log-btn {
  min-width: 87px;
}

.header .main-menu-wrapper .main-nav li a {
  font-weight: 600;
  color: #fff;
  display: block;
  font-size: 17px;
}

@media (max-width: 1399.98px) {
  .header .main-menu-wrapper .main-nav li a {
    font-size: 14px;
  }
}

.header .main-menu-wrapper .main-nav li.login-link {
  display: none;
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav li.login-link {
    display: block;
  }
}

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav li>ul {
    background: #000;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.25);
    border-radius: 0px 0px 6px 5px;
    display: block;
    font-size: 14px;
    min-width: 200px;
    opacity: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    visibility: hidden;
    z-index: 1000;
    border-top: 1px solid #ebbb67;
  }
}

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav li {
    display: block;
    position: relative;
  }
}

.header .main-menu-wrapper .main-nav li.searchbar .togglesearch {
  position: absolute;
  top: 74px;
  right: 8%;
  width: 280px;
  display: none;
  z-index: 999;
  background: #FFF;
  margin: 0;
  padding: 10px;
  border-radius: 5px;
}

.header .main-menu-wrapper .main-nav li.searchbar .togglesearch input[type=text] {
  width: 182px;
  padding: 5px 10px;
  border: 1px solid #ebbb67;
  outline: none;
  height: 39px;
  border-radius: 5px 0 0 5px;
}

.header .main-menu-wrapper .main-nav li.searchbar .togglesearch button {
  background: #ebbb67;
  color: #FFF;
  border: 1px solid #ebbb67;
  outline: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  min-width: auto;
}

.header .main-menu-wrapper .main-nav li.searchbar .togglesearch button:hover {
  color: #ebbb67;
}

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav>li {
    margin-right: 30px;
  }
}

@media (min-width: 1200px) {
  .header .main-menu-wrapper .main-nav>li {
    margin-right: 15px;
  }
}

@media (min-width: 1400px) {
  .header .main-menu-wrapper .main-nav>li {
    margin-right: 22px;
  }
}

@media (max-width: 1199.98px) {
  .header .main-menu-wrapper .main-nav>li {
    margin-right: 15px;
  }
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav>li {
    border-bottom: 1px solid #675dd7;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav>li>a i {
    float: right;
    margin-top: 5px;
  }
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav>li>a {
    line-height: 1.5;
    padding: 15px 20px !important;
    color: #FFF !important;
    font-size: 14px;
    background: transparent !important;
    border-radius: 0 !important;
  }
}

.header .main-menu-wrapper .main-nav>li:last-child {
  margin-right: 0;
}

.header .main-menu-wrapper .main-nav>li.active>a {
  color: #ebbb67;
}

.header .main-menu-wrapper .main-nav>li>a {
  position: relative;
}

.header .main-menu-wrapper .main-nav>li>a:hover {
  color: #ebbb67;
}

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav>li>a {
    padding: 0;
    line-height: 70px;
  }
}

.header .main-menu-wrapper .main-nav>li>a>i {
  font-size: 12px;
  margin-left: 6px;
}

.header .main-menu-wrapper .main-nav>li.searchbar a {
  color: #8A909A;
}

.header .main-menu-wrapper .main-nav>li.searchbar a i {
  font-size: 16px;
}

.header .main-menu-wrapper .main-nav>li.searchbar a:hover img {
  filter: brightness(0) saturate(100%) invert(41%) sepia(33%) saturate(7191%) hue-rotate(230deg) brightness(101%) contrast(99%);
}

@media (max-width: 1199.98px) {
  .header .main-menu-wrapper .main-nav>li.searchbar {
    display: none;
  }
}

.header .main-menu-wrapper .main-nav>li.has-submenu .submenu .has-submenu .submenu::before {
  top: 20px;
  margin-left: -35px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
  border-color: transparent #FFF #FFF transparent;
}

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav>li.has-submenu:hover>.submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
  }

  .header .main-menu-wrapper .main-nav>li.has-submenu:hover>.submenu>li.has-submenu:hover .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -20px;
    margin-right: 0;
    border-top: 1px solid #ebbb67;
  }
}

.header .main-menu-wrapper .main-nav>li .submenu li {
  position: relative;
}

.header .main-menu-wrapper .main-nav>li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  -webkit-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  position: relative;
  border-top: 1px solid #FFF;
  text-transform: capitalize;
}

.header .main-menu-wrapper .main-nav>li .submenu li a.active {
  color: #ebbb67;
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav>li .submenu li a.active {
    color: #FFF;
  }
}

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav>li .submenu li a:hover {
    color: #ebbb67;
  }
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav>li .submenu li a {
    padding: 10px 15px 10px 35px;
    color: #ECF4EF;
    border-top: 1px solid #7D858F;
  }
}

/* .header .main-menu-wrapper .main-nav>li .submenu>li.has-submenu>a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 13px;
} */

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav>li .submenu>li .inner-submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
}

.header .main-menu-wrapper .main-nav>li .submenu::before {
  display: none;
}

@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav>li .submenu::before {
    border: 7px solid #FFF;
    border-color: transparent transparent #FFF #FFF;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
  }
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}

@media (max-width: 991.98px) {
  .header .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    transform: translateX(-260px);
    -webkit-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    background-color: #000;
  }
}

.show-menu {
  display: block;
  /* Show the menu when the show-menu class is applied */
  transform: translateX(0) !important;
  /* Apply a translation effect for a slide-in animation */
}

.header .logo {
  display: inline-block;
  margin-right: 50px;
}

.logo img {
  width: 200px !important;
}

@media (max-width: 1399.98px) {
  .header .logo {
    margin-right: 26px;
    max-width: 200px;
  }
}

@media (max-width: 1199.98px) {
  .header .logo {
    margin-right: 40px;
  }
}

@media (max-width: 991.98px) {
  .header .logo.navbar-brand {
    width: 100%;
    text-align: center;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 1199.98px) {
  .header .logo.navbar-brand img {
    max-width: 165px;
  }
}

.header .header-navbar-rht {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-right: 0;
  margin-left: auto;
}

.header .header-navbar-rht li {
  margin-right: 10px;
}

.header .header-navbar-rht li .btn {
  font-weight: 600;
  font-size: 15px;
  min-width: 108px;
  padding: 11px 15px;
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .header .header-navbar-rht li .btn {
    padding: 10px 15px;
    min-width: 90px;
    font-size: 14px;
  }
}

@media (max-width: 1399.98px) {
  .header .header-navbar-rht li .btn {
    min-width: 95px;
    padding: 10px 10px;
    font-size: 13px;
  }
}

.header .header-navbar-rht li .btn i {
  margin-right: 5px;
}

.header .header-navbar-rht li .btn.sign-btn {
  background-color: #F6F6F9;
  border: 1px solid #F6F6F9;
  color: #8A909A;
  box-shadow: inset 0 0 0 0 #ebbb67;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.header .header-navbar-rht li .btn.sign-btn:hover {
  color: #FFF;
  background-color: #ebbb67;
  border-color: #ebbb67;
  box-shadow: inset 0 50px 0 0 #ebbb67;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.header .header-navbar-rht li:last-child {
  margin-right: 0;
}

.header .header-navbar-rht li.new-property-btn {
  margin-right: 24px;
}

.header .header-navbar-rht li.new-property-btn a {
  font-weight: 600;
  font-size: 15px;
  color: #8A909A;
  line-height: 40px;
}

.header .header-navbar-rht li.new-property-btn a:hover {
  color: #ebbb67;
}

.header .header-navbar-rht li.new-property-btn a i {
  font-size: 20px;
  margin-right: 4px;
  color: #ebbb67;
}

@media (max-width: 1399.98px) {
  .header .header-navbar-rht li.new-property-btn a {
    font-size: 13px;
  }
}

.header .header-navbar-rht li.new-property-btn a.active {
  color: #ebbb67;
}

@media (max-width: 1199.98px) {
  .header .header-navbar-rht li.new-property-btn {
    display: none;
  }
}

@media (max-width: 1399.98px) {
  .header .header-navbar-rht li.new-property-btn {
    margin-right: 12px;
  }
}

.header .header-navbar-rht .has-arrow .dropdown-toggle {
  color: #FFF;
}

.header .header-navbar-rht .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #EEEEEE;
  border-right: 2px solid #EEEEEE;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
  transform-origin: 66% 66%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.header .header-navbar-rht .has-arrow .dropdown-toggle[aria-expanded=true]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}

.header .header-navbar-rht .has-arrow .dropdown-toggle .user-img {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.header .header-navbar-rht .has-arrow .dropdown-toggle .user-img>img {
  height: 46px;
  object-fit: cover;
  width: 46px;
  border: 2px solid #FFF;
}

@media (max-width: 767.78px) {
  .header .header-navbar-rht .has-arrow .dropdown-toggle .user-content {
    display: none;
  }
}

.header .header-navbar-rht .has-arrow .user-header {
  padding: 10px 15px;
}

.header .header-navbar-rht .has-arrow .user-header .user-text {
  margin-left: 10px;
}

.header .header-navbar-rht .has-arrow .user-header .user-text h6 {
  font-size: 15px;
  margin-bottom: 2px;
}

.header .header-navbar-rht .has-arrow .logged-item .nav-link {
  font-size: 14px;
  line-height: 85px;
  padding: 0 10px;
}

.header .header-navbar-rht .has-arrow.logged-item .nav-link {
  font-size: 14px;
  line-height: 80px;
  padding: 0;
}

@media (max-width: 767.78px) {
  .header .header-navbar-rht {
    display: none;
  }
}

.header .menu-header {
  background-color: #FFF;
  height: 70px;
  padding: 0 15px;
  border-bottom: 0;
  display: none;
}

.header .menu-header .menu-close {
  font-size: 20px;
}

.header .menu-header .menu-close i {
  color: #1E1D85;
}

.header .menu-header .menu-close i:hover {
  color: #ebbb67;
}

@media (max-width: 991.98px) {
  .header .menu-header {
    display: flex;
  }
}

.header .menu-header .menu-logo {
  max-width: 180px;
}

.header .main-nav>li .submenu>li .submenu {
  left: 100%;
  top: 0;
  margin-top: 10px;
}

@media (max-width: 991.98px) {
  .header .main-nav>li .submenu>li .submenu {
    left: 0;
    margin-top: 0;
  }
}

.menu-opened {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
}

.main-nav li .flag-dropdown .submenu::before {
  display: none;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.sidebar-overlay.opened {
  display: block;
}

.nav-fixed .header-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #FFF;
}


/*-----------------	
    15. Home
-----------------------*/
.banner-section {
  padding: 50px 0;
  /* background: url(../src/images/banner.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  height: 100vh;
}

@media (max-width: 991.98px) {
  .banner-section {
    padding: 50px 0 50px;
  }
}

@media (max-width: 767.78px) {
  .banner-section {
    padding: 40px 0 40px;
  }
}

@media (max-width: 991.98px) {
  .banner-content {
    text-align: center;
  }
}

.banner-content h1 {
  font-weight: 600;
  font-size: 55px;
  color: #ebbb67;
  line-height: normal;
  margin-bottom: 20px;
}

@media (max-width: 1399.98px) {
  .banner-content h1 {
    font-size: 54px;
  }
}

@media (max-width: 1199.98px) {
  .banner-content h1 {
    font-size: 44px;
  }
}

@media (max-width: 991.98px) {
  .banner-content h1 {
    font-size: 28px;
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767.78px) {
  .banner-content h1 {
    font-size: 26px;
  }
}

.banner-content h1 span {
  color: #ebbb67;
}

.banner-content p {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  margin-bottom: 40px;
  max-width: 570px;
  width: 100%;
}

@media (max-width: 767.78px) {
  .banner-content p {
    font-size: 15px;
    margin-bottom: 30px;
  }
}

.banner-content .btn {
  max-width: 200px;
  font-size: 14px;
  border-radius: 0;
  padding: 16px 20px;
}

.banner-content .btn i {
  margin-right: 10px;
}

@media (max-width: 767.78px) {
  .banner-content .btn {
    max-width: 180px;
    padding: 10px 15px;
  }
}

.banner-search .banner-tab {
  padding-bottom: 20px;
}

.banner-search .banner-tab .nav-tabs {
  margin: 0;
  padding: 0;
  border: 0;
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab .nav-tabs {
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .banner-search .banner-tab .nav-tabs {
    flex-direction: column;
  }
}

.banner-search .banner-tab .nav-tabs .nav-item {
  margin-right: 25px;
}

.banner-search .banner-tab .nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link {
  font-weight: 600;
  font-size: 15px;
  color: #0E0E46;
  border: 1px solid #F6F4FF;
  background: #FFF;
  position: relative;
  border-radius: 10px;
  padding: 17px 20px;
  min-width: 180px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab .nav-tabs .nav-item .nav-link {
    padding: 14px 15px;
    min-width: 160px;
    font-size: 14px;
  }
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link img {
  margin-right: 8px;
  -webkit-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link:focus {
  background: #ebbb67;
  border: 1px solid #ebbb67;
  color: #FFF;
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link:hover {
  background: #ebbb67;
  border: 1px solid #ebbb67;
  color: #FFF;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link:hover img {
  filter: invert(1) brightness(100);
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link.active {
  background: #ebbb67;
  border: 1px solid #ebbb67;
  color: #FFF;
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link.active img {
  filter: invert(1) brightness(100);
}

.banner-search .banner-tab .nav-tabs .nav-item .nav-link.active:before {
  border: 7px solid #ebbb67;
  border-color: #ebbb67;
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
}
.form-control:focus {
  border-color: #ebbb67 !important;
  /* Add any other styles you want to apply when the input is focused */
}
@media (max-width: 575.98px) {
  .banner-search .banner-tab .nav-tabs .nav-item {
    margin: 0 0 10px 0;
  }
}

.banner-search .banner-tab-property {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 30px;
}

@media (max-width: 1399.98px) {
  .banner-search .banner-tab-property {
    padding: 25px;
  }
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info {
    flex-direction: column;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid {
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child {
  margin-right: 0;
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child {
    margin: 0;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child a {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background: #ebbb67;
  color: #FFF;
  border: 1px solid #ebbb67;
  font-size: 20px;
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child a:hover {
  color: #ebbb67;
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child a {
    width: 100%;
  }
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:last-child a {
    height: 50px;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(3) input {
  width: 300px;
}

@media (max-width: 1399.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(3) input {
    width: 260px;
  }
}

@media (max-width: 1199.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(3) input {
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(3) input {
    width: 100%;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(4) input {
  width: 120px;
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(4) input {
    width: 100%;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(5) input {
  width: 120px;
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(5) input {
    width: 100%;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(2) {
  width: 30%;
}

@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid:nth-child(2) {
    width: 100%;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid .form-control {
  background: #F6F6F9;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  border-radius: 10px;
  min-height: 60px;
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid .form-control {
    font-size: 14px;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid .form-control::placeholder {
  font-size: 16px;
  color: #0E0E46;
  font-weight: 400;
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid .form-control::placeholder {
    font-size: 14px;
  }
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid .form-control {
    min-height: 50px;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container .select2-selection--single {
  height: 60px;
  border-radius: 10px;
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container .select2-selection--single {
    height: 50px;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #0E0E46;
  font-size: 16px;
  line-height: 60px;
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
    font-size: 14px;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 60px;
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #0E0E46 transparent transparent;
}

@media (max-width: 767.78px) {
  .banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
  }
}

.banner-search .banner-tab-property .banner-property-info .banner-property-grid .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #0E0E46;
}

@media (max-width: 991.98px) {
  .banner-img-slider .banner-images {
    display: flex;
    justify-content: center;
  }
}

.banner-img-slider .banner-images img {
  border-radius: 50px 0 0 0;
  width: auto;
  text-align: center;
}

.banner-img-slider .owl-dots {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.banner-img-slider .owl-dots button {
  margin-right: 0 !important;
  margin-bottom: 5px;
}

.section-heading {
  margin-bottom: 50px;
}

.section-heading h2 {
  color: #ebbb67;
  position: relative;
  margin: 0 0 15px;
}

@media (max-width: 991.98px) {
  .section-heading h2 {
    font-size: 28px;
  }
}

@media (max-width: 767.78px) {
  .section-heading h2 {
    font-size: 22px;
  }
}

.section-heading h4 {
  color: #727272;
  font-size: 20px;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .section-heading h4 {
    font-size: 18px;
  }
}

@media (max-width: 767.78px) {
  .section-heading h4 {
    font-size: 16px;
  }
}

.section-heading p {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

@media (max-width: 767.78px) {
  .section-heading p {
    font-size: 16px;
  }
}

@media (max-width: 991.98px) {
  .section-heading {
    margin-bottom: 40px;
  }
}

@media (max-width: 767.78px) {
  .section-heading {
    margin-bottom: 30px;
  }
}

.sec-line {
  position: relative;
  margin-bottom: 15px;
  line-height: 0;
}

.sec-line .sec-line1 {
  width: 16px;
  height: 6px;
  background: #FD3358;
  border-radius: 30px;
  display: inline-block;
  margin-right: 5px;
}

.sec-line .sec-line2 {
  width: 46px;
  height: 6px;
  background: #FD3358;
  border-radius: 30px;
  display: inline-block;
}

.howit-work {
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .howit-work {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .howit-work {
    padding: 40px 0 16px;
  }
}

.section-heading {
  flex-direction: column;
}

.section-heading h6 {
  font-size: 28px;
  font-weight: 600;
}

@media (max-width: 575.98px) {
  .section-heading h6 {
    font-size: 24px;
  }
}

.howit-work-card {
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  padding: 24px;
}

@media (max-width: 991.98px) {
  .howit-work-card {
    margin-bottom: 24px;
  }
}

.howit-work-card:hover {
  transform: translatey(-20px);
}

.howit-work-card .work-card-icon {
  margin: 0 0 16px;
}

.howit-work-card .work-card-icon span {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background: #ebbb67;
}

.howit-work-card h4 {
  margin: 0 0 16px;
  font-size: 20px;
}

@media (max-width: 767.78px) {
  .howit-work-card h4 {
    font-size: 18px;
  }
}

.property-type-sec {
  background: #1E1D85;
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .property-type-sec {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .property-type-sec {
    padding: 40px 0;
  }
}

.property-type-sec .section-heading {
  align-items: start;
  margin-bottom: 30px;
}

@media (max-width: 767.78px) {
  .property-type-sec .section-heading {
    align-items: center;
    text-align: center;
  }
}

.property-type-sec .section-heading h2 {
  color: #FFF;
  max-width: 300px;
}

.property-type-sec .section-heading h2:before {
  left: 0;
}

.property-type-sec .section-heading h2:after {
  left: 21px;
}

@media (max-width: 767.78px) {
  .property-type-sec .section-heading h2:after {
    left: 50%;
    transform: translatex(-50%);
  }
}

.property-type-sec .section-heading p {
  color: #FFF;
}

.property-type-sec .section-shape-imgs .rectangle-left {
  position: absolute;
  top: 0;
  left: 0;
}

.property-type-sec .section-shape-imgs .rectangle-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.property-type-sec .owl-nav.mynav1 {
  position: relative;
}

@media (max-width: 767.78px) {
  .property-type-sec .owl-nav.mynav1 {
    margin-bottom: 30px;
  }
}

.property-card {
  flex-direction: column;
  text-align: center;
  padding: 50px 30px;
  background: #FFF;
  border-radius: 10px;
}

.property-card .property-img {
  margin-bottom: 24px;
}

.property-card .property-img img {
  height: 71px;
}

.property-card h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.property-card p,
.property-card span {
  font-weight: 500;
  margin-bottom: 0;
}

.owl-nav button {
  width: 44px;
  height: 44px;
  background: #FFF;
  border-radius: 50%;
  border: none;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.owl-nav button.owl-prev {
  margin-right: 10px;
}

.owl-nav button:hover {
  background: #ebbb67;
  color: #FFF;
}

.product-custom .feature-rating {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 20px 0 20px;
  width: 100%;
}

.product-custom .feature-rating .featured {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
}

.product-custom .feature-rating .new-featured {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  margin-left: 8px;
}

.product-custom .feature-rating .favourite {
  position: relative;
  top: 0;
  right: 0;
}

.bg-01 {
  position: absolute;
  top: 30%;
  right: 100px;
}

@media (max-width: 1399.98px) {
  .bg-01 {
    right: 20px;
    width: 70px;
  }
}

.bg-02 {
  position: absolute;
  right: 100px;
  bottom: 18%;
}

@media (max-width: 1399.98px) {
  .bg-02 {
    right: 40px;
  }
}

.bg-03 {
  position: absolute;
  top: 18%;
  left: 160px;
}

@media (max-width: 1399.98px) {
  .bg-03 {
    left: 40px;
  }
}

.bg-04 {
  position: absolute;
  bottom: 160px;
  left: 25px;
}

.bg-05 {
  position: absolute;
  bottom: 78px;
  left: 23px;
  z-index: -1;
}

.bg-06 {
  position: absolute;
  bottom: 25px;
  left: 150px;
}

@media (max-width: 1399.98px) {
  .bg-06 {
    left: 20px;
  }
}

.bg-07 {
  position: absolute;
  top: 10%;
  right: 170px;
}

@media (max-width: 1399.98px) {
  .bg-07 {
    right: 100px;
  }
}

.bg-08 {
  position: absolute;
  top: 10%;
  right: 100px;
}

@media (max-width: 1399.98px) {
  .bg-08 {
    right: 40px;
  }
}

.bg-09 {
  position: absolute;
  right: 130px;
  bottom: 80px;
}

@media (max-width: 1399.98px) {
  .bg-09 {
    right: 40px;
  }
}

.bg-10 {
  position: absolute;
  bottom: 40px;
  left: -50px;
}

@media (max-width: 1399.98px) {
  .bg-10 {
    left: -40px;
  }
}

.feature-property-sec {
  padding: 80px 0;
  /* background: #F7F6FF; */
  position: relative;
}

@media (max-width: 991.98px) {
  .feature-property-sec {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .feature-property-sec {
    padding: 40px 0;
  }
}

.feature-property-sec .section-heading h2:before {
  left: 185px;
}

.feature-property-sec .section-heading h2:after {
  right: 198px;
}

.feature-property-sec.for-rent .section-heading h2:before {
  left: 176px;
}

.feature-property-sec .feature-slider.owl-carousel .owl-nav {
  text-align: center;
  position: absolute;
  top: -110px;
  right: 0;
}

@media (max-width: 767.78px) {
  .feature-property-sec .feature-slider.owl-carousel .owl-nav {
    position: static;
  }
}

.feature-property-sec .feature-slider.owl-carousel .owl-nav button {
  background: #FFF;
  color: #0E0E46;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
}

.feature-property-sec .feature-slider.owl-carousel .owl-nav button:hover {
  background: #ebbb67;
  color: #FFF;
}

.feature-property-sec .view-property-btn {
  margin-top: 26px;
}

@media (max-width: 991.98px) {
  .feature-property-sec .view-property-btn {
    margin-top: 16px;
  }
}

@media (max-width: 767.78px) {
  .feature-property-sec .view-property-btn {
    margin-top: 30px;
  }
}

.feature-property-sec.for-rent .favourite {
  position: absolute;
  top: unset;
  right: 20px;
  bottom: 20px;
}

.feature-property-sec.for-rent .rating .rating-count {
  margin-right: 5px;
}

.cities-list-sec {
  padding: 80px 0;
}

.cities-list-sec .section-heading h2:before {
  left: 110px;
}

.cities-list-sec .section-heading h2:after {
  right: 100px;
}

@media (max-width: 991.98px) {
  .cities-list-sec {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .cities-list-sec {
    padding: 40px 0;
  }
}

.cities-list-sec .city-list {
  position: relative;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  margin: 0 0 24px;
  border-radius: 10px;
  overflow: hidden;
}

.cities-list-sec .city-list .city-img {
  position: relative;
  border-radius: 10px;
  z-index: -1;
}

.cities-list-sec .city-list .city-img img {
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.cities-list-sec .city-list .city-img:before {
  content: "";
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 1;
}

.cities-list-sec .city-list .city-name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 24px 24px;
}

.cities-list-sec .city-list .city-name h5 {
  color: #FFF;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .cities-list-sec .city-list .city-name h5 {
    font-size: 18px;
  }
}

.cities-list-sec .city-list .city-name span,
.cities-list-sec .city-list .city-name p {
  font-size: 14px;
  font-weight: 500;
  color: #FFF;
  margin-bottom: 0;
}

.cities-list-sec .city-list .arrow-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
}

.cities-list-sec .city-list .arrow-overlay a {
  width: 70px;
  height: 70px;
  background: #ebbb67;
  border-radius: 50%;
  color: #FFF;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  position: relative;
  right: -8px;
  bottom: -15px;
}

@media (max-width: 991.98px) {
  .cities-list-sec .city-list .arrow-overlay a {
    width: 50px;
    height: 50px;
  }
}

.cities-list-sec .city-list:hover .arrow-overlay a {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.cities-list-sec .city-list:hover .city-img {
  z-index: -1;
}

.cities-list-sec .city-list:hover .city-img img {
  transform: scale(1.14);
  -webkit-transform: scale(1.14);
  -ms-transform: scale(1.14);
}

.cities-list-sec .city-list:hover .city-img::before {
  z-index: 1;
}

.city-card-slider .owl-dots {
  margin-top: 26px;
}

@media (max-width: 991.98px) {
  .city-card-slider .owl-dots {
    margin-top: 16px;
  }
}

@media (max-width: 767.78px) {
  .city-card-slider .owl-dots {
    margin-top: 6px;
  }
}

.city-card-slider .owl-dots,
.blog-slider .owl-dots,
.banner-img-slider .owl-dots {
  text-align: center;
  line-height: 0;
}

.city-card-slider .owl-dots button,
.blog-slider .owl-dots button,
.banner-img-slider .owl-dots button {
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #FFF;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.city-card-slider .owl-dots button span,
.blog-slider .owl-dots button span,
.banner-img-slider .owl-dots button span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #E3E3E3;
  display: inline-block;
}

.city-card-slider .owl-dots button.active,
.blog-slider .owl-dots button.active,
.banner-img-slider .owl-dots button.active {
  background: #FFF;
  width: 22px;
  height: 22px;
  border: 2px solid #ebbb67;
  position: relative;
}

.city-card-slider .owl-dots button.active span,
.blog-slider .owl-dots button.active span,
.banner-img-slider .owl-dots button.active span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ebbb67;
}

.counter-sec {
  padding: 80px 0;
  background: #1E1D85;
}

@media (max-width: 991.98px) {
  .counter-sec {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .counter-sec {
    padding: 40px 0 16px;
  }
}

.counter-sec .counter-box {
  text-align: center;
  background: #1E1D85;
  padding: 40px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border-radius: 15px;
}

@media (max-width: 767.78px) {
  .counter-sec .counter-box {
    margin-bottom: 24px;
  }
}

.counter-sec .counter-box.active {
  background: #FFF;
}

.counter-sec .counter-box.active h3 {
  color: #1E1D85;
}

.counter-sec .counter-box.active h5 {
  color: #1E1D85;
}

.counter-sec .counter-box .counter-icon {
  margin-bottom: 20px;
}

.counter-sec .counter-box h3 {
  font-size: 48px;
  color: #FFF;
  margin-bottom: 5px;
}

@media (max-width: 1199.98px) {
  .counter-sec .counter-box h3 {
    font-size: 40px;
  }
}

@media (max-width: 991.98px) {
  .counter-sec .counter-box h3 {
    font-size: 30px;
  }
}

@media (max-width: 767.78px) {
  .counter-sec .counter-box h3 {
    font-size: 24px;
  }
}

.counter-sec .counter-box h5 {
  font-size: 18px;
  color: #FFF;
  font-weight: 500;
  margin-bottom: 0;
}

.counter-sec .counter-box:hover {
  background: #ebbb67;
}

.main-property-sec {
  padding: 80px 0;
  position: relative;
}

@media (max-width: 991.98px) {
  .main-property-sec {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .main-property-sec {
    padding: 40px 0;
  }
}

.single-property-card .img-card {
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  position: relative;
}

.single-property-card .img-card img {
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.single-property-card .img-card:hover img {
  transform: scale(1.14);
  -webkit-transform: scale(1.14);
  -ms-transform: scale(1.14);
}

.single-property-card .img-card:hover::before {
  border: 20px solid transparent;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.single-property-card .img-card::before {
  content: "";
  width: 100%;
  height: 100%;
  border: 20px solid transparent;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

@media (max-width: 991.98px) {
  .single-property-card {
    margin-bottom: 24px;
    text-align: center;
  }
}

.single-property-card .buy-property {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  margin: -55px 20px 0 20px;
  padding: 24px;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 1199.98px) {
  .single-property-card .buy-property {
    margin: -45px 20px 0;
    padding: 20px;
  }
}

@media (max-width: 991.98px) {
  .single-property-card .buy-property {
    margin: -45px auto 0;
    padding: 20px;
    width: 80%;
  }
}

@media (max-width: 767.78px) {
  .single-property-card .buy-property {
    width: 75%;
  }
}

@media (max-width: 575.98px) {
  .single-property-card .buy-property {
    margin: -35px auto 0;
    padding: 15px;
  }
}

.single-property-card .buy-property .arrow {
  width: 58px;
  height: 58px;
  background: #EC4040;
  border: 1px solid #EC4040;
  border-radius: 50%;
  color: #FFF;
  flex-shrink: 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.single-property-card .buy-property .arrow.sell-arrow {
  background: #FCAF3D;
  border: 1px solid #FCAF3D;
}

.single-property-card .buy-property .arrow.sell-arrow:hover {
  background: #FFF;
  color: #FCAF3D;
}

.single-property-card .buy-property .arrow.rent-arrow {
  background: #ebbb67;
  border: 1px solid #ebbb67;
}

.single-property-card .buy-property .arrow.rent-arrow:hover {
  background: #FFF;
  color: #ebbb67;
}

.single-property-card .buy-property .arrow:hover {
  background: #FFF;
  color: #EC4040;
}

@media (max-width: 1199.98px) {
  .single-property-card .buy-property .arrow {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 991.98px) {
  .single-property-card .buy-property .arrow {
    width: 46px;
    height: 46px;
  }
}

.single-property-card .buy-property h4 {
  margin-bottom: 0;
}

.single-property-card .buy-property h4 a {
  color: #0E0E46;
}

.single-property-card .buy-property h4 a:hover {
  color: #ebbb67;
}

@media (max-width: 1199.98px) {
  .single-property-card .buy-property h4 {
    font-size: 22px;
  }
}

@media (max-width: 767.78px) {
  .single-property-card .buy-property h4 {
    font-size: 18px;
  }
}

.main-property-details {
  padding: 0 0 80px;
  position: relative;
}

@media (max-width: 991.98px) {
  .main-property-details {
    padding: 0 0 26px;
  }
}

@media (max-width: 767.78px) {
  .main-property-details {
    padding: 0 0 16px;
  }
}

.partners-sec {
  padding: 80px 0 0;
  border-top: 1px solid #E3E3E3;
}

@media (max-width: 991.98px) {
  .partners-sec {
    padding: 50px 0 0;
  }
}

@media (max-width: 767.78px) {
  .partners-sec {
    padding: 40px 0 0;
  }
}

.partners-sec .section-heading h2:before {
  left: 172px;
}

.partners-sec .section-heading h2:after {
  right: 193px;
}

.partners-sec .section-heading h2 {
  max-width: 430px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .partners-sec .section-heading h2 {
    max-width: 100%;
  }
}

.partners-sec .partner-icon {
  background: #F7F6FF;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
}

.partners-sec .partner-icon img {
  width: auto;
}

.price-section {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .price-section {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .price-section {
    padding: 40px 0 16px;
  }
}

.price-section .pricing-tab {
  margin-bottom: 50px;
}

@media (max-width: 767.78px) {
  .price-section .pricing-tab {
    flex-direction: column;
  }
}

@media (max-width: 767.78px) {
  .price-section .pricing-tab {
    margin-bottom: 40px;
  }
}

.price-section .pricing-tab .section-heading {
  align-items: start;
  margin-bottom: 0;
  text-align: center;
}

@media (max-width: 767.78px) {
  .price-section .pricing-tab .section-heading {
    align-items: center;
    margin-bottom: 40px;
  }
}

.price-section .pricing-tab .section-heading h2::before {
  left: 0;
}

.price-section .pricing-tab .section-heading h2::after {
  left: 20px;
}

@media (max-width: 767.78px) {
  .price-section .pricing-tab .section-heading h2::after {
    left: 50%;
    transform: translatex(-50%);
  }
}

.price-section .pricing-tab ul.nav-pills {
  border: 1px solid #E3E3E3;
  background: #FFF;
  border-radius: 10px;
  max-width: 250px;
}

.price-section .pricing-tab ul.nav-pills .nav-link {
  color: #8A909A;
  font-size: 15px;
  font-weight: 700;
  padding: 11px 30px;
}

.price-section .pricing-tab ul.nav-pills .nav-link.active {
  color: #FFF;
  background: #ebbb67;
}

.price-card {
  border: 1px solid #E3E3E3;
  background: #FFF;
  padding: 35px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
}

@media (max-width: 991.98px) {
  .price-card {
    margin: 0 0 24px;
    padding: 30px;
  }
}

@media (max-width: 767.78px) {
  .price-card {
    margin: 0 0 24px;
    padding: 25px;
  }
}

.price-card .price-sticker {
  position: absolute;
  top: 10px;
  right: -10px;
}

.price-card .price-title {
  margin: 0 0 35px;
}

.price-card .price-title h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 15px;
}

@media (max-width: 1399.98px) {
  .price-card .price-title h3 {
    font-size: 24px;
  }
}

@media (max-width: 991.98px) {
  .price-card .price-title h3 {
    font-size: 22px;
  }
}

@media (max-width: 767.78px) {
  .price-card .price-title h3 {
    font-size: 20px;
  }
}

@media (max-width: 991.98px) {
  .price-card .price-title {
    margin: 0 0 30px;
  }
}

@media (max-width: 767.78px) {
  .price-card .price-title {
    margin: 0 0 25px;
  }
}

.price-card .price-features {
  margin: 0 0 35px 0;
  border-radius: 10px;
}

.price-card .price-features h5 {
  font-size: 16px;
  margin: 0 0 25px;
}

.price-card .price-features ul li {
  margin-bottom: 20px;
  position: relative;
}

.price-card .price-features ul li span {
  margin-right: 10px;
  color: #ebbb67;
}

.price-card .price-features ul li:last-child {
  margin: 0;
}

.price-card .price-features.professional ul li span {
  color: #FCAF3D;
}

.price-card .price-features.enterprise ul li span {
  color: #0DCA95;
}

@media (max-width: 991.98px) {
  .price-card .price-features {
    margin: 0 0 30px;
  }
}

@media (max-width: 767.78px) {
  .price-card .price-features {
    margin: 0 0 25px;
  }
}

.price-card .price-btn a {
  padding: 11px 10px;
  width: 100%;
  background: #ebbb67;
  display: block;
  text-align: center;
  font-size: 15px;
  color: #FFF;
  border-radius: 10px;
  font-weight: 700;
}

.price-card .price-btn a:hover {
  color: #ebbb67;
}

.price-card .price-btn a:focus {
  color: #ebbb67;
}

.product-custom {
  width: 100%;
}

.product-custom .profile-widget {
  background-color: #FFF;
  box-shadow: 0px 25px 30px rgba(189, 189, 189, 0.24);
  position: relative;
  border: 5px solid #ebbb67;
  border-radius: 18px;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.product-custom .doc-img,
.product-custom .pro-img {
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border-radius: 10px 10px 0 0;
}

.product-custom .doc-img .property-img,
.product-custom .pro-img .property-img {
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border-radius: 10px 10px 0 0;
  display: block;
}

.product-custom .doc-img .property-img:before,
.product-custom .pro-img .property-img:before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.product-custom .doc-img .property-img img,
.product-custom .pro-img .property-img img {
  border-radius: 10px 10px 0 0;
  z-index: -1;
  position: relative;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  width: 100%;
}

.product-custom .doc-img:hover .property-img img,
.product-custom .pro-img:hover .property-img img {
  transform: scale(1.14);
}

.product-custom .doc-img .favourite.selected,
.product-custom .pro-img .favourite.selected {
  background: #0DCA95;
  border: 1px solid #0DCA95;
}

.product-custom .doc-img .user-avatar,
.product-custom .pro-img .user-avatar {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.product-custom .doc-img .user-avatar img,
.product-custom .pro-img .user-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.product-custom .product-amount {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.product-custom .product-amount span {
  font-weight: 600;
  font-size: 24px;
  color: #FFF;
}

@media (max-width: 991.98px) {
  .product-custom .product-amount span {
    font-size: 20px;
  }
}

@media (max-width: 767.78px) {
  .product-custom .product-amount span {
    font-size: 18px;
  }
}

.product-custom .product-amount h5 {
  font-size: 14px;
  color: #FFF;
  font-weight: 500;
  margin-bottom: 0;
}

.product-custom .featured {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.product-custom .featured span {
  font-weight: 500;
  font-size: 14px;
  color: #FFF;
  background-color: #ebbb67;
  padding: 5px 8px;
  display: inline-block;
  border-radius: 8px;
}

.product-custom .new-featured {
  position: absolute;
  top: 20px;
  left: 105px;
  z-index: 1;
}

.product-custom .new-featured span {
  font-weight: 500;
  font-size: 14px;
  color: #FFF;
  background-color: #FF0000;
  padding: 5px 8px;
  display: inline-block;
  border-radius: 8px;
}

.product-custom .favourite {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #FFF;
  z-index: 1;
  text-align: center;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.product-custom .favourite:hover {
  background: #0DCA95;
  border: 1px solid #0DCA95;
}

.product-custom i {
  color: #FFF;
}

.pro-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  border-radius: 0 0 4px 4px;
  border: 3px solid #ebbb67 !important;
}

@media (max-width: 575.98px) {
  .pro-content {
    padding: 20px;
  }
}

.pro-content .rating {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.pro-content .checked {
  color: #FCAF3D;
}

.pro-content .details {
  margin: 0 0 20px;
  padding: 20px 20px 15px;
  background-color: #F7F6FF;
  font-size: 14px;
  align-items: center;
}

.pro-content .details li {
  color: #0E0E46;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  .pro-content .details li {
    margin-right: 7px;
  }
}

.pro-content .details li img {
  margin-right: 8px;
  width: auto;
}

@media (max-width: 991.98px) {
  .pro-content .details li img {
    margin-right: 5px;
  }
}

.pro-content .details li:last-child {
  margin-right: 0;
}

@media (max-width: 1399.98px) {
  .pro-content .details {
    margin: 0 0 20px;
    padding: 15px 15px 10px;
  }
}

@media (max-width: 991.98px) {
  .pro-content .details {
    margin: 0 0 20px;
    padding: 10px 10px 5px;
  }
}

.pro-content h3 {
  margin-bottom: 11px;
  font-size: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 1199.98px) {
  .pro-content h3 {
    font-size: 22px;
  }
}

@media (max-width: 767.78px) {
  .pro-content h3 {
    font-size: 18px;
  }
}

.pro-content h3 a {
  color: #0E0E46;
  text-transform: capitalize;
}

.pro-content h3 a:hover {
  color: #ebbb67;
}

.pro-content p {
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 20px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.pro-content p i {
  font-size: 14px;
  color: #8A909A;
  margin-right: 5px;
  line-height: 0;
}

.pro-content p span {
  line-height: 0;
}

@media (max-width: 575.98px) {
  .pro-content p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.pro-content .list {
  font-size: 15px;
  font-weight: 700;
}

@media (max-width: 1399.98px) {
  .pro-content .list {
    font-size: 14px;
  }
}

.pro-content .date {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 1399.98px) {
  .pro-content .date {
    font-size: 13px;
  }
}

.feature-property-sec.for-rent .property-category {
  padding-top: 20px;
  border-top: 1px solid #f4f4f4;
}

.property-category .user-info span,
.property-category .user-info a {
  flex-shrink: 0;
}

.property-category .user-info span img,
.property-category .user-info a img {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  margin-right: 8px;
}

@media (max-width: 991.98px) {

  .property-category .user-info span img,
  .property-category .user-info a img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 575.98px) {

  .property-category .user-info span img,
  .property-category .user-info a img {
    width: 40px;
    height: 40px;
  }
}

.property-category .user-info .user-name {
  flex-direction: column;
  overflow: hidden;
}

.property-category .user-info .user-name h6 {
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 1199.98px) {
  .property-category .user-info .user-name h6 {
    font-size: 16px;
  }
}

@media (max-width: 767.78px) {
  .property-category .user-info .user-name h6 {
    font-size: 15px;
  }
}

.property-category .user-info .user-name h6 a {
  margin-bottom: 0;
}

.property-category .user-info .user-name a {
  color: #0E0E46;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
}

.property-category .user-info .user-name a:hover {
  color: #ebbb67;
}

@media (max-width: 1199.98px) {
  .property-category .user-info .user-name a {
    font-size: 16px;
  }
}

@media (max-width: 575.98px) {
  .property-category .user-info .user-name a {
    font-size: 15px;
  }
}

.property-category .user-info .user-name span,
.property-category .user-info .user-name p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {

  .property-category .user-info .user-name span,
  .property-category .user-info .user-name p {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .property-category .user-info {
    width: calc(100% - 100px);
  }
}

.property-category .btn-primary {
  padding: 11px 15px;
}

@media (max-width: 1199.98px) {
  .property-category .btn-primary {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .property-category {
    flex-wrap: wrap;
  }
}



/*-----------------	
    11. Footer
-----------------------*/
.footer {
  background: #10162E;
  position: relative;
}

.footer .footer-top {
  padding: 60px 0;
}

@media (max-width: 991.98px) {
  .footer .footer-top {
    padding: 40px 0 10px;
  }
}

.footer .footer-top .footer-border-img img {
  position: absolute;
  top: 0;
  right: 0;
}

.footer .footer-top .footer-content-heading h4 {
  color: #FFF;
  font-size: 24px;
  margin-bottom: 30px;
}

@media (max-width: 1199.98px) {
  .footer .footer-top .footer-content-heading h4 {
    font-size: 22px;
  }
}

@media (max-width: 991.98px) {
  .footer .footer-top .footer-content-heading h4 {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

@media (max-width: 767.78px) {
  .footer .footer-top .footer-content-heading h4 {
    font-size: 18px;
  }
}

.footer .footer-top .footer-content-heading p {
  color: #f2f2f2;
  margin-bottom: 15px;
}

.footer .footer-top .download-app {
  margin-bottom: 50px;
  display: flex;
}

.footer .footer-top .download-app img {
  margin-right: 5px;
}

@media (max-width: 991.98px) {
  .footer .footer-top .download-app img {
    max-width: 140px;
  }
}

@media (max-width: 575.98px) {
  .footer .footer-top .download-app {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .footer .footer-top .download-app img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 991.98px) {
  .footer .footer-top .download-app {
    margin-bottom: 30px;
  }
}

@media (max-width: 991.98px) {
  .footer .footer-top .footer-about {
    margin-bottom: 30px;
  }
}

.social-links h4 {
  font-size: 24px;
  font-weight: 600;
  color: #FFF;
  margin-bottom: 15px;
  text-transform: capitalize;
}

@media (max-width: 1199.98px) {
  .social-links h4 {
    font-size: 22px;
  }
}

@media (max-width: 991.98px) {
  .social-links h4 {
    font-size: 20px;
  }
}

@media (max-width: 767.78px) {
  .social-links h4 {
    font-size: 18px;
  }
}

.social-links ul {
  display: flex;
}

.social-links ul li {
  margin-right: 10px;
}

.social-links ul li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FFF;
  color: #0E0E46;
  font-size: 18px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.social-links ul li a:hover {
  background: #ebbb67;
  color: #FFF;
}

@media (max-width: 767.78px) {
  .social-links ul li a {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }
}

.social-links ul li:last-child {
  margin: 0;
}

@media (max-width: 991.98px) {
  .footer-widget-list {
    margin-bottom: 30px;
  }
}

.footer-widget-list ul li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 18px;
}

.footer-widget-list ul li i {
  color: #FCAF3D;
  margin-right: 10px;
}

.footer-widget-list ul li a {
  color: #f2f2f2;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.footer-widget-list ul li a:hover {
  color: #FCAF3D;
}

.footer-widget-list ul li:last-child {
  margin: 0;
}

.footer-bottom {
  background: #0D1329;
  padding: 15px 0;
}

.footer-bottom-content {
  color: #FFF;
}

@media (max-width: 767.78px) {
  .footer-bottom-content {
    flex-direction: column;
    align-items: start;
  }
}

.footer-bottom-content p {
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 767.78px) {
  .footer-bottom-content p {
    margin-bottom: 10px;
  }
}

.footer-bottom-content .company-logo img {
  margin-left: 5px;
}

.login-body .footer-social-links {
  margin: 10px 0 30px;
}

.login-body .footer-social-links h6 {
  font-size: 18px;
  color: #FFF;
  margin-bottom: 15px;
}

@media (max-width: 767.78px) {
  .login-body .footer-social-links h6 {
    margin-bottom: 10px;
  }
}

.login-body .footer-social-links .nav li {
  margin-right: 10px;
}

.login-body .footer-social-links .nav li:last-child {
  margin-right: 0;
}

.login-body .footer-social-links .nav li a {
  cursor: pointer;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: #ebbb67;
  background: #F6F4FF;
  border-radius: 50px;
  min-width: 36px;
  height: 36px;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}

.login-body .footer-social-links .nav li a:hover {
  background: #ebbb67;
  color: #FFF;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}

.login-body .footer-social-widget {
  margin: 20px 0 0;
}

.login-body .footer-social-widget .nav-social li {
  margin-right: 10px;
}

.login-body .footer-social-widget .nav-social li:last-child {
  margin-right: 0;
}

.login-body .footer-social-widget .nav-social li a:hover {
  color: #000;
}


/*-----------------	
    14. Breadcrumb
-----------------------*/
.breadcrumb {
  margin: 0;
  padding: 90px 0 !important;
  /* background-image: url('https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/breadcrumb-bg.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
}

.breadcrumb:before {
  content: "";
  background: #ebbb67;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.breadcrumb .bread-crumb-head {
  text-align: center;
  position: relative;
}

.breadcrumb .bread-crumb-head .breadcrumb-title h2 {
  color: #fff;
  font-size: 36px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media (max-width: 991.98px) {
  .breadcrumb .bread-crumb-head .breadcrumb-title h2 {
    font-size: 28px;
  }
}

@media (max-width: 767.78px) {
  .breadcrumb .bread-crumb-head .breadcrumb-title h2 {
    font-size: 24px;
  }
}

.breadcrumb-list ul {
  padding: 0 !important;
}

.breadcrumb .bread-crumb-head .breadcrumb-list ul li {
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.breadcrumb .bread-crumb-head .breadcrumb-list ul li span {
  color: #FCAF3D;
  margin: 0 7px 0 7px;
  padding: 0;
}

.breadcrumb .bread-crumb-head .breadcrumb-list ul li a {
  color: #FFF;
}

.breadcrumb .bread-crumb-head .breadcrumb-list ul li a:hover {
  color: #FCAF3D;
}

.breadcrumb .bread-crumb-head .breadcrumb-list ul li+li {
  padding-left: 7px;
}

.breadcrumb .bread-crumb-head .breadcrumb-list ul li+li::before {
  content: "/";
  float: left;
  padding-right: 7px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.breadcrumb .breadcrumb-border-img img {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 991.98px) {
  .breadcrumb {
    padding: 60px 0;
  }
}

@media (max-width: 767.78px) {
  .breadcrumb {
    padding: 50px 0;
  }
}


/*-----------------	
    29. About Us
-----------------------*/
.aboutus-section {
  padding: 80px 0 0 !important;
}

@media (max-width: 991.98px) {
  .aboutus-page .aboutus-section {
    padding: 50px 0 0;
  }
}

.aboutus-page .about-img {
  position: relative;
  margin: 0 0 24px 0;
}

.aboutus-page .about-img img {
  border-radius: 10px;
  padding: 0 0 0 10px;
}

.aboutus-page .about-img .about-exp {
  position: absolute;
  background: #ebbb67;
  border-radius: 10px;
  width: 370px;
  height: 100%;
}

@media (max-width: 767.78px) {
  .aboutus-page .about-img .about-exp {
    width: 230px;
  }
}

.aboutus-page .about-img .about-exp span {
  font-weight: 500;
  font-size: 28px;
  color: #FFF;
  display: inline-block;
  margin: 250px -122px;
  transform: rotate(-90deg);
}

@media (max-width: 767.78px) {
  .aboutus-page .about-img .about-exp span {
    font-size: 18px;
    margin: 275px -90px;
  }
}

@media (max-width: 575.98px) {
  .aboutus-page .about-img .about-exp span {
    font-size: 16px;
    margin: 115px -75px;
  }
}

@media (max-width: 991.98px) {
  .aboutus-page .about-content {
    margin: 0 0 40px 0;
  }
}

.aboutus-page .about-content h2 {
  /* font-size: 15px; */
  color: #ebbb67;
  font-weight: 700;
  margin: 0 0 20px;
}

.aboutus-page .about-content h1 {
  font-size: 48px;
  font-weight: 700;
  max-width: 657px;
  margin: 0 0 20px;
  color: #ebbb67;
}

@media (max-width: 991.98px) {
  .aboutus-page .about-content h1 {
    margin: 0 0 24px;
    font-size: 38px;
  }
}

@media (max-width: 767.78px) {
  .aboutus-page .about-content h1 {
    margin: 0 0 20px;
    font-size: 28px;
  }
}

.aboutus-page .about-content p {
  margin: 0 0 20px;
  color: #fff;
}

.aboutus-page .about-counter-sec {
  position: relative;
  margin: 60px 0 0;
}

@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec {
    margin: 50px 0 0;
  }
}

@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec {
    margin: 36px 0 0;
  }
}

.aboutus-page .about-counter-sec:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 769px;
  background: #F7F6FF;
  text-align: center;
  left: 0;
  right: 0;
  top: 29%;
}

.aboutus-page .about-counter-sec .about-listing-img .about-listing {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.aboutus-page .about-counter-sec .about-listing-img .about-listing img {
  border-radius: 20px;
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  /* max-width: 415px; */
  width: 100%;
  height: 400px;
}

@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .about-listing-img .about-listing img {
    width: 100%;
    max-width: 100%;
  }
}

.aboutus-page .about-counter-sec .about-listing-img .about-listing img:hover {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}

@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .about-listing-img .about-listing {
    margin-bottom: 24px;
  }
}

.aboutus-page .about-counter-sec .about-listing-img .about-img-middle {
  margin: -34px 0 0;
}

@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .about-listing-img .about-img-middle {
    margin: 24px 0;
  }
}

.aboutus-page .about-counter-sec .counter-sec {
  position: relative;
  background: transparent;
}

@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec .counter-sec {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .counter-sec {
    padding: 24px 0 26px;
  }
}

.aboutus-page .about-counter-sec .counter-sec .counter-box {
  background: #FFF;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}

.aboutus-page .about-counter-sec .counter-sec .counter-box:hover {
  background-color: #ebbb67;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}

.aboutus-page .about-counter-sec .counter-sec .counter-box:hover h3 {
  color: #FFF;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}

.aboutus-page .about-counter-sec .counter-sec .counter-box:hover h5 {
  color: #FFF;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}

.aboutus-page .about-counter-sec .counter-sec .counter-box h3 {
  font-size: 36px;
  color: #0E0E46;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}

@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec .counter-sec .counter-box h3 {
    font-size: 30px;
  }
}

@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .counter-sec .counter-box h3 {
    font-size: 24px;
  }
}

.aboutus-page .about-counter-sec .counter-sec .counter-box h5 {
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
  color: #8A909A;
}

.aboutus-page .about-counter-sec .counter-sec .counter-box h5:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec .counter-sec .counter-box {
    margin: 0 0 24px;
  }
}

.aboutus-page .book-section {
  background: #1E1D85;
}

.aboutus-page .book-section .book-listing h2 {
  font-size: 48px;
  color: #FFF;
  margin: 0 0 34px;
}

@media (max-width: 991.98px) {
  .aboutus-page .book-section .book-listing h2 {
    font-size: 38px;
    margin: 0 0 24px;
  }
}

@media (max-width: 767.78px) {
  .aboutus-page .book-section .book-listing h2 {
    font-size: 26px;
    margin: 0 0 20px;
  }
}

@media (max-width: 991.98px) {
  .aboutus-page .book-section .book-listing {
    margin: 0 0 24px;
  }
}

.aboutus-page .book-section .book-content p {
  color: #FFF;
}

.aboutus-page .book-section .book-content p span {
  color: #FCAF3D;
}

.aboutus-page .partners-sec {
  border-top: none;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .aboutus-page .partners-sec {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .aboutus-page .partners-sec {
    padding: 40px 0;
  }
}


/*-----------------
	13. Common
-----------------------*/
.custom_check {
  color: #8A909A;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
}

.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_check input:checked~.checkmark {
  background-color: #ebbb67;
  border-color: #ebbb67;
}

.custom_check input:checked~.checkmark:after {
  display: block;
}

.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #f4f4f4;
  background-color: #F6F6F9;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Fontawesome";
  font-weight: 700;
  position: absolute;
  display: none;
  color: #FFF;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
}

.custom_radio {
  color: #0E0E46;
  display: inline-block;
  position: relative;
  font-size: 14px;
  padding-top: 3px;
  padding-right: 35px;
  margin-right: 50px;
  margin-bottom: 0 !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_radio:last-child {
  margin-right: 0;
}

.custom_radio input {
  position: absolute;
  opacity: 0;
}

.custom_radio input:checked~.checkmark:after {
  opacity: 1;
}

.custom_radio .checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #F6F6F9;
  border: 2px solid #0E0E46;
  border-radius: 50%;
}

.custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  left: -1px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 5px solid #ebbb67;
  background: #FFF;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.error-page {
  padding: 50px;
  align-items: center;
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .error-page {
    padding: 50px;
  }
}

.error-page .main-wrapper {
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  min-height: unset;
}

.error-box {
  margin: auto;
  padding: 98px 0;
  text-align: center;
  max-width: 630px;
}

@media (max-width: 1399.98px) {
  .error-box {
    margin: auto;
    padding: 60px 0;
  }
}

@media (max-width: 991.98px) {
  .error-box {
    margin: auto;
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .error-box {
    margin: auto;
    padding: 40px 0;
  }
}

.error-box img {
  margin: 0 0 40px;
}

.error-box h1 {
  font-size: 54px;
  margin: 0 0 11px;
}

@media (max-width: 1399.98px) {
  .error-box h1 {
    font-size: 42px;
  }
}

@media (max-width: 991.98px) {
  .error-box h1 {
    font-size: 28px;
  }
}

@media (max-width: 767.78px) {
  .error-box h1 {
    font-size: 26px;
  }
}

.error-box p {
  margin: auto auto 30px;
  font-weight: 500;
  max-width: 574px;
}

.error-box .error-content {
  max-width: 318px !important;
}

.error-box h6 {
  font-weight: 500;
  font-size: 16px;
}

.error-box .btn-maintance {
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  padding: 12px 20px;
}

@media (max-width: 767.78px) {
  .error-box .btn-maintance {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.error-box .error-500 {
  margin: 0 0 60px;
}

.error-box .coming-soon {
  margin: 0 0 20px;
}

.error-box .back-button .btn {
  padding: 11px 13px;
  font-size: 15px;
  color: #0E0E46;
  font-weight: 600;
  border-radius: 10px;
  background-color: #F6F6F9;
  border: 1px solid #F6F6F9;
  box-shadow: inset 0 0 0 0 #FFF;
}

@media (max-width: 575.98px) {
  .error-box .back-button .btn {
    padding: 10px;
    font-size: 14px;
  }
}

.error-box .back-button .btn:focus,
.error-box .back-button .btn:hover,
.error-box .back-button .btn:active,
.error-box .back-button .btn.active {
  color: #FFF;
  background-color: #FFF;
  border-color: #F6F6F9;
  box-shadow: inset 0 50px 0 0 #ebbb67;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.error-box.come-box p {
  margin-bottom: 20px;
}

.serve-form {
  margin: 0 0 39px;
}

@media (max-width: 767.78px) {
  .serve-form {
    padding-bottom: 30px;
  }
}

.serve-form .form-group {
  margin-bottom: 0;
}

.serve-form .form-group .form-control {
  margin-right: 10px;
  min-height: 45px;
}

.serve-form .form-group .form-control::placeholder {
  color: #828282;
  font-weight: 500;
}

@media (max-width: 767.78px) {
  .serve-form .form-group .form-control {
    min-height: 46px;
    font-size: 14px;
  }
}

.serve-form .form-group .button-notific {
  min-width: 115px;
  height: 45px;
  font-weight: 600;
  font-size: 15px;
}

@media (max-width: 767.78px) {
  .serve-form .form-group .button-notific {
    min-width: 100px;
    font-size: 14px;
  }
}

.privacy-section {
  position: relative;
}

.privacy-section .terms-policy p {
  margin: 0 0 38px;
}

@media (max-width: 991.98px) {
  .privacy-section .terms-policy p {
    margin: 0 0 28px;
  }
}

@media (max-width: 767.78px) {
  .privacy-section .terms-policy p {
    margin: 0 0 20px;
  }
}

.privacy-section .terms-policy ul {
  margin: 0 0 38px;
}

.privacy-section .terms-policy ul li {
  margin: 0 0 11px;
}

.privacy-section .terms-policy ul li span {
  margin-right: 10px;
  color: #ebbb67;
}

.section {
  position: relative;
  padding: 60px 0;
}

@media (max-width: 991.98px) {
  .section {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .section {
    padding: 40px 0;
  }
}

.section.faq-section {
  padding: 60px 0;
}

@media (max-width: 991.98px) {
  .section.faq-section {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .section.faq-section {
    padding: 40px 0;
  }
}

.section.price-section {
  padding: 60px 0;
}

@media (max-width: 991.98px) {
  .section.price-section {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .section.price-section {
    padding: 40px 0 16px;
  }
}

.listing-section {
  position: relative;
  padding: 60px 0 36px;
}

@media (max-width: 991.98px) {
  .listing-section {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .listing-section {
    padding: 40px 0 16px;
  }
}

.listing-section.feature-property-sec {
  padding: 60px 0 36px;
}

@media (max-width: 991.98px) {
  .listing-section.feature-property-sec {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .listing-section.feature-property-sec {
    padding: 40px 0 16px;
  }
}

.btn-outline-light {
  color: #FFF;
  background-color: #ebbb67;
  border: 1px solid #ebbb67;
  box-shadow: inset 0 0 0 0 #FFF;
}

.btn-outline-light:hover {
  background-color: #FFF;
  border-color: #ebbb67;
  color: #ebbb67;
  box-shadow: inset 0 50px 0 0 #FFF;
}

.btn-size {
  padding: 18px 10px;
  line-height: 23px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .btn-size {
    padding: 15px 10px;
    line-height: 15px;
  }
}

.ownerMessage {
  background-color: transparent !important;
}

/* Faq */


.faq-section {
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .faq-section {
    padding: 50px 0;
  }
}

@media (max-width: 767.78px) {
  .faq-section {
    padding: 40px 0;
  }
}

.faq-section .section-heading {
  align-items: start;
  margin-bottom: 28px;
}

@media (max-width: 767.78px) {
  .faq-section .section-heading {
    align-items: center;
    text-align: center;
  }
}

.faq-section .section-heading h2::before {
  left: 0;
}

.faq-section .section-heading h2::after {
  left: 20px;
}

@media (max-width: 767.78px) {
  .faq-section .section-heading h2::after {
    left: 50%;
    transform: translatex(-50%);
  }
}

@media (max-width: 767.78px) {
  .faq-section .section-heading p {
    font-size: 16px;
  }
}

.faq-section .faq-img {
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  .faq-section .faq-img {
    text-align: center;
    margin-bottom: 24px;
  }
}

@media (max-width: 767.78px) {
  .faq-section .faq-img {
    display: none;
  }
}

.faq-section .faq-img img {
  border-radius: 10px;
}

.faq-card {
  background: #FFF;
  margin: 0 0 20px;
  border: 1px solid #E3E3E3;
}

.faq-card:last-child {
  margin-bottom: 0;
}

.faq-card .card-collapse .faq-info {
  margin: 0 20px;
  padding: 15px 0 20px 0;
  border-top: 1px solid #E3E3E3;
}

.faq-card .faq-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.faq-card .faq-title a {
  color: #0E0E46;
  display: block;
  position: relative;
  padding: 20px 60px 15px 20px;
}

@media (max-width: 575.98px) {
  .faq-card .faq-title a {
    font-size: 15px;
    padding-right: 40px;
  }
}

.faq-card .faq-title a.collapsed {
  padding-bottom: 20px;
}

.faq-card .faq-title>a:after {
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  content: "\f078";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.faq-card .faq-title>a:not(.collapsed):after {
  content: "\f077";
  color: #8A909A;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #0E0E46 !important;
}

.accordion-button {
  color: #0E0E46 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}


:after,
:before {
  --tw-content: ""
}

:host,
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent
}

body {
  margin: 0;
  line-height: inherit
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit
}

a {
  color: inherit;
  text-decoration: inherit
}

b,
strong {
  font-weight: bolder
}

code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0
}

button,
select {
  text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
  /* background-color: transparent; */
  background-image: none
}

:-moz-focusring {
  outline: auto
}

:-moz-ui-invalid {
  box-shadow: none
}

progress {
  vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

summary {
  display: list-item
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0
}

fieldset {
  margin: 0
}

fieldset,
legend {
  padding: 0
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0
}

dialog {
  padding: 0
}

textarea {
  resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af
}

[role=button],
button {
  cursor: pointer
}

:disabled {
  cursor: default
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle
}

img,
video {
  max-width: 100%;
  height: auto
}

[hidden] {
  display: none
}

*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.inset-0 {
  inset: 0
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.-bottom-24 {
  bottom: -6rem
}

.-bottom-4 {
  bottom: -1rem
}

.-bottom-5 {
  bottom: -1.25rem
}

.-right-2 {
  right: -.5rem
}

.-top-16 {
  top: -4rem
}

.-top-8 {
  top: -2rem
}

.bottom-14 {
  bottom: 3.5rem
}

.bottom-2 {
  bottom: 15px;
}

.bottom-20 {
  bottom: 5rem
}

.bottom-4 {
  bottom: 1rem
}

.bottom-\[-1\.9rem\] {
  bottom: -1.9rem
}

.bottom-\[-2\.1rem\] {
  bottom: -2.1rem
}

.left-0 {
  left: 0
}

.left-1\/2 {
  left: 50%
}

.left-2 {
  left: .5rem
}

.left-4 {
  left: 1rem
}

.right-0 {
  right: 0
}

.right-2 {
  right: .5rem
}

.right-\[-5rem\] {
  right: -5rem
}

.top-0 {
  top: 0
}

.top-1\/2 {
  top: 50%
}

.top-16 {
  top: 4rem
}

.top-2 {
  top: .5rem
}

.top-20 {
  top: 5rem
}

.top-32 {
  top: 8rem
}

.top-4 {
  top: 1rem
}

.top-5 {
  top: 1.25rem
}

.top-6 {
  top: 1.5rem
}

.top-8 {
  top: 2rem
}

.top-\[-3\.7rem\] {
  top: -3.7rem
}

.top-\[-3\.8rem\] {
  top: -3.8rem
}

.top-\[-4\.2rem\] {
  top: -4.2rem
}

.top-\[0\] {
  top: 0
}

.top-\[8rem\] {
  top: 8rem
}

.top-full {
  top: 100%
}

.-z-10 {
  z-index: -10
}

.-z-30 {
  z-index: -30
}

.-z-50 {
  z-index: -50
}

.-z-\[1\] {
  z-index: -1
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-\[-1\] {
  z-index: -1
}

.z-\[1\] {
  z-index: 1
}

.z-\[2\] {
  z-index: 2
}

.order-first {
  order: -9999
}

.col-span-1 {
  grid-column: span 1/span 1
}

.col-span-2 {
  grid-column: span 2/span 2
}

.col-span-3 {
  grid-column: span 3/span 3
}

.col-start-1 {
  grid-column-start: 1
}

.col-end-3 {
  grid-column-end: 3
}

.row-span-2 {
  grid-row: span 2/span 2
}

.m-10 {
  margin: 2.5rem
}

.m-2 {
  margin: .5rem
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px
}

.-mb-16 {
  margin-bottom: -4rem
}

.-mb-5 {
  margin-bottom: -1.25rem
}

.-mr-1 {
  margin-right: -.25rem
}

.-mt-12 {
  margin-top: -3rem
}

.-mt-14 {
  margin-top: -3.5rem
}

.-mt-16 {
  margin-top: -4rem
}

.-mt-2 {
  margin-top: -.5rem
}

.-mt-24 {
  margin-top: -6rem
}

.-mt-5 {
  margin-top: -1.25rem
}

.-mt-6 {
  margin-top: -1.5rem
}

.-mt-\[5\.5rem\] {
  margin-top: -5.5rem
}

.mb-0 {
  margin-bottom: 0
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-16 {
  margin-bottom: 4rem
}

.mb-2 {
  margin-bottom: .5rem
}

.mb-24 {
  margin-bottom: 6rem
}

.mb-3 {
  margin-bottom: .75rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mb-\[-1\.2rem\] {
  margin-bottom: -1.2rem
}

.mb-\[-1\.4rem\] {
  margin-bottom: -1.4rem
}

.mb-\[-4rem\] {
  margin-bottom: -4rem
}

.ml-0 {
  margin-left: 0
}

.ml-2 {
  margin-left: .5rem
}

.ml-5 {
  margin-left: 1.25rem
}

.ml-\[-3rem\] {
  margin-left: -3rem
}

.mr-0 {
  margin-right: 0
}

.mr-2 {
  margin-right: .5rem
}

.mr-auto {
  margin-right: auto
}

.mt-0 {
  margin-top: 0
}

.mt-1 {
  margin-top: .25rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mt-14 {
  margin-top: 3.5rem
}

.mt-16 {
  margin-top: 4rem
}

.mt-2 {
  margin-top: .5rem
}

.mt-20 {
  margin-top: 5rem
}

.mt-24 {
  margin-top: 6rem
}

.mt-28 {
  margin-top: 7rem
}

.mt-3 {
  margin-top: .75rem
}

.mt-32 {
  margin-top: 8rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mt-\[-1\.5rem\] {
  margin-top: -1.5rem
}

.mt-\[-2\.2rem\] {
  margin-top: -2.2rem
}

.mt-\[-2rem\] {
  margin-top: -2rem
}

.mt-\[-3\.7rem\] {
  margin-top: -3.7rem
}

.mt-\[4rem\] {
  margin-top: 4rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.aspect-\[12\/8\] {
  aspect-ratio: 12/8
}

.aspect-\[13\/9\] {
  aspect-ratio: 13/9
}

.aspect-\[18\/9\] {
  aspect-ratio: 18/9
}

.aspect-\[4\/3\] {
  aspect-ratio: 4/3
}

.aspect-square {
  aspect-ratio: 1/1
}

.aspect-video {
  aspect-ratio: 16/9
}

.h-0 {
  height: 0
}

.h-10 {
  height: 2.5rem
}

.h-12 {
  height: 3rem
}

.h-16 {
  height: 4rem
}

.h-2\/4 {
  height: 50%
}

.h-20 {
  height: 5rem
}

.h-24 {
  height: 6rem
}

.h-32 {
  height: 8rem
}

.h-36 {
  height: 9rem
}

.h-4 {
  height: 1rem
}

.h-40 {
  height: 10rem
}

.h-44 {
  height: 11rem
}

.h-48 {
  height: 12rem
}

.h-5 {
  height: 1.25rem
}

.h-5\/6 {
  height: 83.333333%
}

.h-56 {
  height: 14rem
}

.h-6 {
  height: 1.5rem
}

.h-60 {
  height: 15rem
}

.h-64 {
  height: 16rem
}

.h-7 {
  height: 1.75rem
}

.h-72 {
  height: 18rem
}

.h-8 {
  height: 2rem
}

.h-96 {
  height: 24rem
}

.h-\[1px\] {
  height: 1px
}

.h-\[20rem\] {
  height: 20rem
}

.h-\[33rem\] {
  height: 33rem
}

.h-\[50vh\] {
  height: 50vh
}

.h-\[60vh\] {
  height: 60vh
}

.h-\[70vh\] {
  height: 70vh
}

.h-\[80vh\] {
  height: 80vh
}

.h-auto {
  height: auto
}


.h-screen {
  height: 100vh
}

.max-h-32 {
  max-height: 8rem
}

.max-h-\[14rem\] {
  max-height: 14rem
}

.max-h-\[calc\(100vh-8rem\)\] {
  max-height: calc(100vh - 8rem)
}

.max-h-full {
  max-height: 100%
}

.min-h-\[50vh\] {
  min-height: 50vh
}

.min-h-\[90vh\] {
  min-height: 90vh
}

.min-h-full {
  min-height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.w-0 {
  width: 0
}

.w-1\/12 {
  width: 8.333333%
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/5 {
  width: 20%
}

.w-10\/12 {
  width: 83.333333%
}

.w-11\/12 {
  width: 91.666667%
}

.w-12 {
  width: 3rem
}

.w-14 {
  width: 3.5rem
}

.w-16 {
  width: 4rem
}

.w-2\/12 {
  width: 16.666667%
}

.w-2\/3 {
  width: 66.666667%
}

.w-20 {
  width: 5rem
}

.w-24 {
  width: 6rem
}

.w-32 {
  width: 8rem
}

.w-4 {
  width: 1rem
}

.w-4\/5 {
  width: 80%
}

.w-40 {
  width: 10rem
}

.w-48 {
  width: 12rem
}

.w-5 {
  width: 1.25rem
}

.w-5\/6 {
  width: 83.333333%
}

.w-52 {
  width: 13rem
}

.w-6 {
  width: 1.5rem
}

.w-64 {
  width: 16rem
}

.w-7 {
  width: 1.75rem
}

.w-8 {
  width: 2rem
}

.w-9 {
  width: 2.25rem
}

.w-9\/12 {
  width: 75%
}

.w-\[60\%\] {
  width: 60%
}

.w-auto {
  width: auto
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.min-w-\[16rem\] {
  min-width: 16rem
}

.min-w-full {
  min-width: 100%
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-4xl {
  max-width: 56rem
}

.max-w-5xl {
  max-width: 64rem
}

.max-w-\[16rem\] {
  max-width: 16rem
}

.max-w-\[55\%\] {
  max-width: 55%
}

.max-w-\[6rem\] {
  max-width: 6rem
}

.max-w-full {
  max-width: 100%
}

.max-w-lg {
  max-width: 32rem
}

.max-w-md {
  max-width: 28rem
}

.max-w-sm {
  max-width: 24rem
}

.max-w-xl {
  max-width: 36rem
}

.max-w-xs {
  max-width: 20rem
}

.flex-1 {
  flex: 1 1 0%
}

.flex-none {
  flex: none
}

.shrink {
  flex-shrink: 1
}

.flex-grow,
.grow {
  flex-grow: 1
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%
}

.-translate-x-1\/2,
.-translate-y-1\/2 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%
}

.-translate-y-32 {
  --tw-translate-y: -8rem
}

.-translate-y-32,
.-translate-y-96 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-96 {
  --tw-translate-y: -24rem
}

.translate-y-0 {
  --tw-translate-y: 0px
}

.scale-\[1\.005\],
.translate-y-0 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-\[1\.005\] {
  --tw-scale-x: 1.005;
  --tw-scale-y: 1.005
}

.scale-\[1\.1\] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

.scale-\[1\.1\],
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
}

@keyframes spin {
  to {
    transform: rotate(1turn)
  }
}

.animate-spin {
  animation: spin 1s linear infinite
}

.cursor-not-allowed {
  cursor: not-allowed
}

.cursor-pointer {
  cursor: pointer
}

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-items-stretch {
  justify-items: stretch
}

.gap-1 {
  gap: .25rem
}

.gap-10 {
  gap: 2.5rem
}

.gap-12 {
  gap: 3rem
}

.gap-16 {
  gap: 4rem
}

.gap-2 {
  gap: .5rem
}

.gap-32 {
  gap: 8rem
}

.gap-4 {
  gap: 1rem
}

.gap-5 {
  gap: 1.25rem
}

.gap-6 {
  gap: 1.5rem
}

.gap-8 {
  gap: 2rem
}

.gap-x-0 {
  -moz-column-gap: 0;
  column-gap: 0
}

.gap-x-2 {
  -moz-column-gap: .5rem;
  column-gap: .5rem
}

.gap-y-2 {
  row-gap: .5rem
}

.gap-y-4 {
  row-gap: 1rem
}

.gap-y-5 {
  row-gap: 1.25rem
}

.space-x-0>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-10>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-2>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-5>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-6>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-10>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
}

.space-y-2>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse))
}

.space-y-3>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse))
}

.space-y-4>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-y-5>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
}

.space-y-6>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
}

.space-y-7>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
}

.space-y-reverse>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 1
}

.divide-x>:not([hidden])~:not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y>:not([hidden])~:not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.overflow-hidden {
  overflow: hidden
}

.overflow-visible {
  overflow: visible
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-scroll {
  overflow-y: scroll
}

.whitespace-nowrap {
  white-space: nowrap
}

.rounded {
  border-radius: .25rem
}

.rounded-3xl {
  border-radius: 1.5rem
}

.rounded-\[0\.8rem\] {
  border-radius: .8rem
}

.rounded-\[1\.25rem\] {
  border-radius: 1.25rem
}

.rounded-\[2rem\] {
  border-radius: 2rem
}

.rounded-\[3\.125rem\] {
  border-radius: 3.125rem
}

.rounded-\[3rem\] {
  border-radius: 3rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: .5rem
}

.rounded-md {
  border-radius: .375rem
}

.rounded-sm {
  border-radius: .125rem
}

.rounded-xl {
  border-radius: .75rem
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0
}

.border-2 {
  border: 1px solid black;
  border-radius: 10px;
}

.border-4 {
  border-width: 4px
}

.border-\[3px\] {
  border-width: 3px
}

.border-b {
  border-bottom-width: 1px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-2 {
  border-left-width: 2px
}

.border-r {
  border-right-width: 1px
}

.border-r-2 {
  border-right-width: 2px
}

.border-t {
  border-top-width: 1px
}

.border-t-2 {
  border-top-width: 2px
}

.border-t-4 {
  border-top-width: 4px
}

.border-double {
  border-style: double
}

.border-\[\#999c\] {
  border-color: #999c
}

.border-\[\#CDAC62\] {
  --tw-border-opacity: 1;
  border-color: rgb(205 172 98/var(--tw-border-opacity))
}

.border-\[\#D4B071\] {
  border-bottom: 3px solid #D4B071 !important;
}

.border-\[\#b3b3b3\] {
  --tw-border-opacity: 1;
  border-color: rgb(179 179 179/var(--tw-border-opacity))
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0/var(--tw-border-opacity))
}

.border-black\/10 {
  border-color: rgba(0, 0, 0, .1)
}

.border-black\/40 {
  border-color: rgba(0, 0, 0, .4)
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247/var(--tw-border-opacity))
}

.border-mainColor {
  --tw-border-opacity: 1;
  border-color: rgb(70 70 70/var(--tw-border-opacity))
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0/var(--tw-border-opacity))
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255/var(--tw-border-opacity))
}

.bg-\[\#CDAC62\] {
  --tw-bg-opacity: 1;
  background-color: rgb(205 172 98/var(--tw-bg-opacity))
}

.bg-\[\#D4B071\] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 176 113/var(--tw-bg-opacity))
}

.bg-\[\#ccc2\] {
  background-color: #ccc2
}

.bg-\[\#d4b071\] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 176 113/var(--tw-bg-opacity))
}

.bg-\[\#f28f9c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 143 156/var(--tw-bg-opacity))
}

.bg-\[\#fbfbfb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 251 251/var(--tw-bg-opacity))
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0/var(--tw-bg-opacity))
}

.bg-black\/50 {
  background-color: rgba(0, 0, 0, .5)
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247/var(--tw-bg-opacity))
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(0 136 0/var(--tw-bg-opacity))
}

.bg-light {
  --tw-bg-opacity: 1;
  background-color: rgb(251 250 246/var(--tw-bg-opacity))
}

.bg-mainColor,
.bg-mainColorLight {
  --tw-bg-opacity: 1;
  background-color: rgb(70 70 70/var(--tw-bg-opacity))
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0/var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.bg-white\/30 {
  background-color: hsla(0, 0%, 100%, .3)
}

.bg-white\/70 {
  background-color: hsla(0, 0%, 100%, .7)
}

.bg-white\/\[\.14\] {
  background-color: hsla(0, 0%, 100%, .14)
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75
}

.bg-cover {
  background-size: cover
}

.bg-fixed {
  background-attachment: fixed
}

.bg-bottom {
  background-position: bottom
}

.bg-center {
  background-position: 50%
}

.bg-top {
  background-position: top
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.fill-black {
  fill: #000
}

.fill-white {
  fill: #fff
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover
}

.object-center {
  -o-object-position: center;
  object-position: center
}

.object-right {
  -o-object-position: right;
  object-position: right
}

.object-top {
  -o-object-position: top;
  object-position: top
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: .25rem
}

.p-10 {
  padding: 2.5rem
}

.p-12 {
  padding: 3rem
}

.p-2 {
  padding: .5rem
}

.p-3 {
  padding: .75rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-7 {
  padding: 1.75rem
}

.p-8 {
  padding: 2rem
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.px-px {
  padding-left: 1px;
  padding-right: 1px
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-14 {
  padding-bottom: 3.5rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pb-2 {
  padding-bottom: .5rem
}

.pb-3 {
  padding-bottom: .75rem
}

.pb-32 {
  padding-bottom: 8rem
}

.pb-36 {
  padding-bottom: 9rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pl-14 {
  padding-left: 3.5rem
}

.pl-2 {
  padding-left: .5rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pl-8 {
  padding-left: 2rem
}

.pt-12 {
  padding-top: 3rem
}

.pt-16 {
  padding-top: 4rem
}

.pt-2 {
  padding-top: .5rem
}

.pt-20 {
  padding-top: 5rem
}

.pt-24 {
  padding-top: 6rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pt-8 {
  padding-top: 2rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.font-Butler {
  font-family: Butler, sans-serif
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-5xl {
  font-size: 3rem;
  line-height: 1
}

.text-\[0\.9rem\] {
  font-size: .9rem
}

.text-\[calc\(20px\+6vw\)\] {
  font-size: calc(20px + 6vw)
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem
}

.font-black {
  font-weight: 900
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.leading-10 {
  line-height: 2.5rem
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.leading-7 {
  line-height: 1.75rem
}

.leading-9 {
  line-height: 2.25rem
}

.leading-loose {
  line-height: 2
}

.leading-none {
  line-height: 1
}

.tracking-tight {
  letter-spacing: -.025em
}

.tracking-wide {
  letter-spacing: .025em
}

.tracking-wider {
  letter-spacing: .05em
}

.text-\[\#000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0/var(--tw-text-opacity))
}

.text-\[\#222\] {
  --tw-text-opacity: 1;
  color: rgb(34 34 34/var(--tw-text-opacity))
}

.text-\[\#555\] {
  --tw-text-opacity: 1;
  color: rgb(85 85 85/var(--tw-text-opacity))
}

.text-\[\#CDAC62\] {
  --tw-text-opacity: 1;
  color: rgb(205 172 98/var(--tw-text-opacity))
}

.text-\[\#D4B071\],
.text-\[\#d4b071\] {
  --tw-text-opacity: 1;
  color: #ebbb67;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0/var(--tw-text-opacity))
}

.text-black\/30 {
  color: rgba(0, 0, 0, .3)
}

.text-black\/80 {
  color: rgba(0, 0, 0, .8)
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(8 12 125/var(--tw-text-opacity))
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(28 25 23/var(--tw-text-opacity))
}

.text-gold {
  --tw-text-opacity: 1;
  color: rgb(205 172 98/var(--tw-text-opacity))
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(247 247 247/var(--tw-text-opacity))
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(0 136 0/var(--tw-text-opacity))
}

.text-light {
  --tw-text-opacity: 1;
  color: rgb(251 250 246/var(--tw-text-opacity))
}

.text-mainColor {
  --tw-text-opacity: 1;
  color: rgb(70 70 70/var(--tw-text-opacity))
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(255 0 0/var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity))
}

.placeholder-black::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(0 0 0/var(--tw-placeholder-opacity))
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(0 0 0/var(--tw-placeholder-opacity))
}

.placeholder-opacity-75::-moz-placeholder {
  --tw-placeholder-opacity: 0.75
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75
}

.opacity-0 {
  opacity: 0
}

.opacity-100 {
  opacity: 1
}

.opacity-30 {
  opacity: .3
}

.opacity-50 {
  opacity: .5
}

.opacity-60 {
  opacity: .6
}

.opacity-70 {
  opacity: .7
}

.opacity-75 {
  opacity: .75
}

.opacity-80 {
  opacity: .8
}

.opacity-90 {
  opacity: .9
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color)
}

.shadow,
.shadow-2xl {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)
}

.shadow-lg,
.shadow-md {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color)
}

.shadow-sm,
.shadow-xl {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color)
}

.shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored)
}

.shadow-black\/20 {
  --tw-shadow-color: rgba(0, 0, 0, .2);
  --tw-shadow: var(--tw-shadow-colored)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.blur {
  --tw-blur: blur(8px)
}

.blur,
.brightness-50 {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.brightness-50 {
  --tw-brightness: brightness(.5)
}

.brightness-\[\.60\] {
  --tw-brightness: brightness(.60)
}

.brightness-\[\.60\],
.brightness-\[\.6\] {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.brightness-\[\.6\] {
  --tw-brightness: brightness(.6)
}

.brightness-\[0\.60\] {
  --tw-brightness: brightness(0.60)
}

.brightness-\[0\.60\],
.brightness-\[0\.65\] {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.brightness-\[0\.65\] {
  --tw-brightness: brightness(0.65)
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, .1)) drop-shadow(0 1px 1px rgba(0, 0, 0, .06))
}

.drop-shadow,
.drop-shadow-lg {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, .04)) drop-shadow(0 4px 3px rgba(0, 0, 0, .1))
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, .07)) drop-shadow(0 2px 2px rgba(0, 0, 0, .06))
}

.drop-shadow-md,
.drop-shadow-xl {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, .03)) drop-shadow(0 8px 5px rgba(0, 0, 0, .08))
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.delay-100 {
  transition-delay: .1s
}

.duration-1000 {
  transition-duration: 1s
}

.duration-200 {
  transition-duration: .2s
}

.duration-300 {
  transition-duration: .3s
}

.duration-500 {
  transition-duration: .5s
}

.duration-700 {
  transition-duration: .7s
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 30px
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border-color: #fff transparent;
  border-style: solid;
  border-width: 2px;
  animation: lds-dual-ring 1.2s linear infinite
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}

@media(min-width: 767px) {
  .container {
    padding: 0 2rem !important
  }
}

*,
:after,
:before {
  box-sizing: border-box;
  outline: none;
  box-shadow: none
}

::-webkit-scrollbar {
  width: 5px
}

.hideScroll::-webkit-scrollbar {
  width: 0
}

::-webkit-scrollbar-thumb {
  background: #777
}

::-webkit-scrollbar-thumb:hover {
  background: #555
}

.noScrollBarr::-webkit-scrollbar {
  width: 0
}

html {
  overflow-x: hidden
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.lines {
  display: -webkit-box;
  white-space: inherit;
  -webkit-box-orient: vertical
}

.lines,
.lines.three {
  -webkit-line-clamp: 3
}

.lines.five {
  -webkit-line-clamp: 5
}

.lines.two {
  -webkit-line-clamp: 2 !important
}

.glass {
  background: rgba(0, 0, 0, .45);
  box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  max-width: calc(100% - 2rem);
  left: 1rem
}

.Projects img {
  transition: all .5s ease-in-out
}

.Projects img:hover {
  filter: brightness(.7)
}

.slick-track {
  display: flex;
  align-items: center
}

.slick-next,
.slick-prev {
  color: #fff !important;
  background: #d4b071 !important;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: .3rem !important;
  z-index: 49
}

.slick-next:before,
.slick-prev:before {
  opacity: 1 !important
}

.slick-prev:before {
  content: "→" !important;
  content: "←" !important
}

.slick-list:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  background: #fff;
  z-index: 5
}

.checkBox:hover input~.checkmark {
  background-color: #ccc
}

.checkBox input:checked~.checkmark {
  background-color: #d4b071
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none
}

.checkBox input:checked~.checkmark:after {
  display: block
}

.checkBox .checkmark:after {
  left: 9px;
  top: 2.5px;
  width: 7.5px;
  height: 15px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg)
}

.MapView div[aria-hidden=true] {
  padding: .1rem .2;
  background: #fff;
  width: 8.5rem;
  white-space: break-spaces;
  text-align: center;
  line-height: 1.5;
  border: .1rem solid;
  color: #000 !important;
  border-radius: .1rem;
  margin-top: 1rem;
  font-size: .8rem
}

.AreaCard {
  border: 5px solid #ebbb67;
  border-radius: 50px;
}

.AreaCard img,
.TeamCard img {
  transition: all 1s ease-in-out
}

.AreaCard img:hover,
.TeamCard img:hover {
  filter: brightness(1.1)
}

.bkDrop {
  z-index: 999
}

.Modal {
  z-index: 9999
}

.ltr {
  direction: ltr
}

.blurGlass {
  background: hsla(0, 0%, 100%, .719);
  box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px)
}

.GoldClass {
  background: #000;
  color: #d4b071;
  border: 2px solid #d4b071
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

input[type=number] {
  -moz-appearance: textfield
}

.border-gradient {
  border: 2px solid #d4b071
}

input[type=date]::-webkit-calendar-picker-indicator,
input[type=time]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto
}

html[dir=ltr] body {
  font-family: Quicksand, sans-serif
}

html[dir=rtl] .rtl {
  direction: rtl
}

.circle {
  width: 10px;
  height: 10px;
  background: #d4b071;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 6s linear infinite
}

@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-40px) rotate(0deg)
  }

  to {
    transform: rotate(1turn) translate(-40px) rotate(-1turn)
  }
}

.Manger {
  border-radius: 4rem 12rem 4rem 12rem
}

.Manger:before {
  border-radius: 4rem 10rem 4rem 10rem;
  position: absolute;
  content: "";
  background: #d4b071;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  transform: scaleY(1.02)
}

.round {
  width: 50%;
  height: auto;
  font-weight: 700;
  shape-outside: circle(50%)
}

html[dir=rtl] .facebookBtn.rtl\:text-right *,
html[dir=rtl] .googleBtn.rtl\:text-right * {
  text-align: right !important
}

html[dir=rtl] .serviceCard:nth-child(odd) {
  direction: ltr;
  text-align: right
}

html[dir=rtl] .serviceCard:nth-child(odd) .flex.items-center.text-gray-800.-px-3 {
  direction: ltr
}

html[dir=rtl] .round {
  float: left
}

html[dir=ltr] .round {
  float: right
}

html[dir=ltr] .facebookBtn.ltr\:text-left *,
html[dir=ltr] .googleBtn.ltr\:text-left * {
  text-align: left !important
}

html[dir=ltr] .serviceCard:nth-child(odd) {
  direction: rtl;
  text-align: left
}

html[dir=ltr] .serviceCard:nth-child(odd) .flex.items-center.text-gray-800.-px-3 {
  direction: ltr
}

@media screen and (max-width: 1024px) {
  .Manger {
    border-radius: 1rem 2rem
  }

  .Manger:before {
    border-radius: 1.5rem 2.5rem
  }

  .round {
    float: none;
    width: 100%;
    shape-outside: none
  }
}

.overlay {
  z-index: 999;
  bottom: -200%;
  transition: all 1s ease-in-out
}

.overlay.active {
  bottom: 0
}

.filter {
  height: 85vh;
  overflow-y: auto;
  z-index: 999;
  bottom: -100%;
  box-shadow: 1px 1px 5px #000;
  transition: all 1s ease-in-out
}

.filter.active {
  bottom: 0
}

.swal2-styled.swal2-confirm:focus,
button.swal2-confirm.swal2-styled {
  border: 0;
  border-radius: .25em;
  padding: .5rem 3rem;
  background: initial;
  background-color: #d4b071;
  color: #fff;
  font-size: 1em;
  box-shadow: none !important;
  outline: none
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #d4b071
}

.lds-spinner div:first-child {
  transform: rotate(0deg);
  animation-delay: -1.1s
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -.9s
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -.8s
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -.7s
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -.6s
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -.5s
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -.4s
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -.3s
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -.2s
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -.1s
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s
}

.devList ol {
  list-style: auto !important
}

@keyframes lds-spinner {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@media(max-width: 1280px) {
  .container {
    max-width: 100%;
    padding: 0 2rem
  }
}

@media(min-width: 1535px)and (max-width:1700px) {
  .container {
    max-width: 100%;
    padding: 0 2rem
  }
}

@media(max-width: 767px) {
  .container {
    padding: 0 1rem
  }
}

@media(min-width: 767px)and (max-width:1023px) {
  .container {
    max-width: 100%;
    padding: 0 2rem
  }
}

.ImgContainer p img {
  margin: 0 auto;
  border-radius: 3rem;
  padding: .5rem 0 !important
}

.ImgContainer {
  background: none !important;
  border: none !important
}

.react-tel-input .country-list .country {
  direction: ltr;
  text-align: left
}

@media(max-width: 575px) {
  .react-tel-input .country-list {
    left: -2rem
  }
}

.placeholder\:text-center::-moz-placeholder {
  text-align: center
}

.placeholder\:text-center::placeholder {
  text-align: center
}

.placeholder\:font-black::-moz-placeholder {
  font-weight: 900
}

.placeholder\:font-black::placeholder {
  font-weight: 900
}

.placeholder\:text-black::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 0 0/var(--tw-text-opacity))
}

.placeholder\:text-black::placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 0 0/var(--tw-text-opacity))
}

.placeholder\:text-transparent::-moz-placeholder {
  color: transparent
}

.placeholder\:text-transparent::placeholder {
  color: transparent
}

.hover\:-translate-y-2:hover {
  --tw-translate-y: -0.5rem
}

.hover\:-translate-y-2:hover,
.hover\:scale-110:hover {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:bg-\[\#f5f5f5\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245/var(--tw-bg-opacity))
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0/var(--tw-bg-opacity))
}

.hover\:bg-mainColor:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(70 70 70/var(--tw-bg-opacity))
}

.hover\:bg-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0/var(--tw-bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1
}

.hover\:text-light:hover {
  --tw-text-opacity: 1;
  color: rgb(251 250 246/var(--tw-text-opacity))
}

.hover\:text-mainColor:hover {
  --tw-text-opacity: 1;
  color: rgb(70 70 70/var(--tw-text-opacity))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity))
}

.hover\:opacity-60:hover {
  opacity: .6
}

.hover\:opacity-90:hover {
  opacity: .9
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:drop-shadow-2xl:hover {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, .15));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0/var(--tw-border-opacity))
}

.focus\:border-b-black:focus {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 0 0/var(--tw-border-opacity))
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

@media not all and (min-width: 1024px) {
  .max-lg\:mt-10 {
    margin-top: 2.5rem
  }
}

@media (min-width: 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:max-w-xl {
    max-width: 36rem
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .sm\:text-center {
    text-align: center
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

@media (min-width: 768px) {
  .md\:-bottom-\[15\%\] {
    bottom: -15%
  }

  .md\:-right-\[10\%\] {
    right: -10%
  }

  .md\:-top-24 {
    top: -6rem
  }

  .md\:top-1\/2 {
    top: 50%
  }

  .md\:z-10 {
    z-index: 10
  }

  .md\:order-none {
    order: 0
  }

  .md\:col-span-1 {
    grid-column: span 1/span 1
  }

  .md\:col-span-2 {
    grid-column: span 2/span 2
  }

  .md\:col-span-4 {
    grid-column: span 4/span 4
  }

  .md\:col-span-8 {
    grid-column: span 8/span 8
  }

  .md\:row-span-1 {
    grid-row: span 1/span 1
  }

  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .md\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  .md\:-mb-24 {
    margin-bottom: -6rem
  }

  .md\:-ml-\[8\%\] {
    margin-left: -8%
  }

  .md\:-mt-16 {
    margin-top: -4rem
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem
  }

  .md\:mb-12 {
    margin-bottom: 3rem
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem
  }

  .md\:mb-4 {
    margin-bottom: 1rem
  }

  .md\:mb-8 {
    margin-bottom: 2rem
  }

  .md\:mb-\[-1\.9rem\] {
    margin-bottom: -1.9rem
  }

  .md\:ml-\[-2rem\] {
    margin-left: -2rem
  }

  .md\:ml-\[3\.8rem\] {
    margin-left: 3.8rem
  }

  .md\:mr-6 {
    margin-right: 1.5rem
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mt-12 {
    margin-top: 3rem
  }

  .md\:mt-2 {
    margin-top: .5rem
  }

  .md\:mt-24 {
    margin-top: 6rem
  }

  .md\:mt-28 {
    margin-top: 7rem
  }

  .md\:mt-32 {
    margin-top: 8rem
  }

  .md\:mt-4 {
    margin-top: 1rem
  }

  .md\:mt-8 {
    margin-top: 2rem
  }

  .md\:mt-\[-2\.5rem\] {
    margin-top: -2.5rem
  }

  .md\:block {
    display: block
  }

  .md\:flex {
    display: flex
  }

  .md\:hidden {
    display: none
  }

  .md\:aspect-\[20\/9\] {
    aspect-ratio: 20/9
  }

  .md\:h-20 {
    height: 5rem
  }

  .md\:h-28 {
    height: 7rem
  }

  .md\:h-48 {
    height: 12rem
  }

  .md\:h-\[35rem\] {
    height: 35rem
  }

  .md\:h-\[75vh\] {
    height: 75vh
  }

  .md\:h-\[85vh\] {
    height: 85vh
  }

  .md\:h-auto {
    height: auto
  }

  .md\:h-full {
    height: 100%
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-1\/5 {
    width: 20%
  }

  .md\:w-12 {
    width: 3rem
  }

  .md\:w-2\/3 {
    width: 66.666667%
  }

  .md\:w-2\/5 {
    width: 40%
  }

  .md\:w-2\/6 {
    width: 33.333333%
  }

  .md\:w-28 {
    width: 7rem
  }

  .md\:w-3\/12 {
    width: 25%
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-3\/6 {
    width: 50%
  }

  .md\:w-4\/12 {
    width: 33.333333%
  }

  .md\:w-4\/5 {
    width: 80%
  }

  .md\:w-4\/6 {
    width: 66.666667%
  }

  .md\:w-5\/12 {
    width: 41.666667%
  }

  .md\:w-5\/6 {
    width: 83.333333%
  }

  .md\:w-7\/12 {
    width: 58.333333%
  }

  .md\:w-8\/12 {
    width: 66.666667%
  }

  .md\:w-9\/12 {
    width: 75%
  }

  .md\:w-\[32rem\] {
    width: 32rem
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-full {
    width: 100%
  }

  .md\:max-w-2xl {
    max-width: 42rem
  }

  .md\:max-w-4xl {
    max-width: 56rem
  }

  .md\:max-w-5xl {
    max-width: 64rem
  }

  .md\:max-w-full {
    max-width: 100%
  }

  .md\:max-w-lg {
    max-width: 32rem
  }

  .md\:max-w-md {
    max-width: 28rem
  }

  .md\:max-w-xs {
    max-width: 20rem
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:flex-wrap {
    flex-wrap: wrap
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:justify-start {
    justify-content: flex-start
  }

  .md\:justify-end {
    justify-content: flex-end
  }

  .md\:gap-x-2 {
    -moz-column-gap: .5rem;
    column-gap: .5rem
  }

  .md\:gap-x-5 {
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .md\:space-x-12>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-2>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-4>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-5>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-0>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .md\:space-y-5>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem
  }


  .md\:rounded-\[2rem\] {
    border-radius: 2rem
  }

  .md\:rounded-\[3\.25rem\] {
    border-radius: 3.25rem
  }

  .md\:rounded-\[3rem\] {
    border-radius: 3rem
  }

  .md\:rounded-full {
    border-radius: 9999px
  }

  .md\:rounded-r-\[3rem\] {
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem
  }

  .md\:border-4 {
    border-width: 4px
  }

  .md\:border-b-0 {
    border-bottom-width: 0
  }

  .md\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity))
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-10 {
    padding: 2.5rem
  }

  .md\:p-12 {
    padding: 3rem
  }

  .md\:p-14 {
    padding: 3.5rem
  }

  .md\:p-2 {
    padding: .5rem
  }

  .md\:p-28 {
    padding: 7rem
  }

  .md\:p-4 {
    padding: 1rem
  }

  .md\:p-5 {
    padding: 1.25rem
  }

  .md\:p-6 {
    padding: 1.5rem
  }

  .md\:p-8 {
    padding: 2rem
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pb-12 {
    padding-bottom: 3rem
  }

  .md\:pb-4 {
    padding-bottom: 1rem
  }

  .md\:pb-8 {
    padding-bottom: 2rem
  }

  .md\:pl-5 {
    padding-left: 1.25rem
  }

  .md\:pr-0 {
    padding-right: 0
  }

  .md\:pr-4 {
    padding-right: 1rem
  }

  .md\:pt-0 {
    padding-top: 0
  }

  .md\:pt-10 {
    padding-top: 2.5rem
  }

  .md\:pt-16 {
    padding-top: 4rem
  }

  .md\:pt-20 {
    padding-top: 5rem
  }

  .md\:pt-28 {
    padding-top: 7rem
  }

  .md\:pt-5 {
    padding-top: 1.25rem
  }

  .md\:pt-8 {
    padding-top: 2rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1
  }

  .md\:text-\[1rem\] {
    font-size: 1rem
  }

  .md\:text-\[calc\(20px\+4vw\)\] {
    font-size: calc(20px + 4vw)
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  .md\:leading-10,
  .md\:leading-\[2\.5rem\] {
    line-height: 2.5rem
  }

  .md\:leading-\[3\.5rem\] {
    line-height: 3.5rem
  }

  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity))
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
  }
}

@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute
  }

  .lg\:bottom-0 {
    bottom: 0
  }

  .lg\:top-\[-4\.2rem\] {
    top: -4.2rem
  }

  .lg\:top-\[-4\.5rem\] {
    top: -4.5rem
  }

  .lg\:col-span-1 {
    grid-column: span 1/span 1
  }

  .lg\:col-span-2 {
    grid-column: span 2/span 2
  }

  .lg\:col-span-4 {
    grid-column: span 4/span 4
  }

  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem
  }

  .lg\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:mb-16 {
    margin-bottom: 4rem
  }

  .lg\:mb-2 {
    margin-bottom: .5rem
  }

  .lg\:mb-8 {
    margin-bottom: 2rem
  }

  .lg\:ml-0 {
    margin-left: 0
  }

  .lg\:ml-24 {
    margin-left: 6rem
  }

  .lg\:mt-0 {
    margin-top: 0
  }

  .lg\:mt-28 {
    margin-top: 7rem
  }

  .lg\:mt-\[-4rem\] {
    margin-top: -4rem
  }

  .lg\:block {
    display: block
  }

  .lg\:flex {
    display: flex
  }

  .lg\:hidden {
    display: none
  }

  .lg\:h-12 {
    height: 3rem
  }

  .lg\:h-56 {
    height: 14rem
  }

  .lg\:h-80 {
    height: 20rem
  }

  .lg\:h-\[32rem\] {
    height: 32rem
  }

  .lg\:h-\[75vh\] {
    height: 75vh
  }

  .lg\:h-full {
    height: 100%
  }

  .lg\:h-screen {
    height: 100vh
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:w-10\/12 {
    width: 83.333333%
  }

  .lg\:w-16 {
    width: 4rem
  }

  .lg\:w-2\/4 {
    width: 50%
  }

  .lg\:w-2\/5 {
    width: 40%
  }

  .lg\:w-3\/12 {
    width: 25%
  }

  .lg\:w-3\/4 {
    width: 75%
  }

  .lg\:w-4\/12 {
    width: 33.333333%
  }

  .lg\:w-6\/12 {
    width: 50%
  }

  .lg\:w-9\/12 {
    width: 75%
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-screen {
    width: 100vw
  }

  .lg\:max-w-2xl {
    max-width: 42rem
  }

  .lg\:max-w-full {
    max-width: 100%
  }

  .lg\:max-w-lg {
    max-width: 32rem
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px
  }

  .lg\:max-w-sm {
    max-width: 24rem
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:flex-col {
    flex-direction: column
  }

  .lg\:items-start {
    align-items: flex-start
  }

  .lg\:items-center {
    align-items: center
  }

  .lg\:justify-end {
    justify-content: flex-end
  }

  .lg\:gap-x-20 {
    -moz-column-gap: 5rem;
    column-gap: 5rem
  }

  .lg\:space-x-3>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-4>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-8>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-0>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .lg\:rounded-\[3\.25rem\] {
    border-radius: 3.25rem
  }

  .lg\:rounded-\[3rem\] {
    border-radius: 3rem
  }

  .lg\:border-4 {
    border-width: 4px
  }

  .lg\:border-r-2 {
    border-right-width: 2px
  }

  .lg\:p-12 {
    padding: 3rem
  }

  .lg\:p-4 {
    padding: 1rem
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:pb-2 {
    padding-bottom: .5rem
  }

  .lg\:pb-20 {
    padding-bottom: 5rem
  }

  .lg\:pr-5 {
    padding-right: 1.25rem
  }

  .lg\:pr-8 {
    padding-right: 2rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pt-20 {
    padding-top: 5rem
  }

  .lg\:pt-6 {
    padding-top: 1.5rem
  }

  .lg\:text-left {
    text-align: left
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1
  }

  .lg\:text-\[calc\(20px\+1vw\)\] {
    font-size: calc(20px + 1vw)
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  .lg\:leading-9 {
    line-height: 2.25rem
  }
}

@media (min-width: 1280px) {
  .xl\:mb-0 {
    margin-bottom: 0
  }

  .xl\:mt-24 {
    margin-top: 6rem
  }

  .xl\:w-1\/3 {
    width: 33.333333%
  }

  .xl\:w-1\/4 {
    width: 25%
  }

  .xl\:w-5\/12 {
    width: 41.666667%
  }

  .xl\:w-7\/12 {
    width: 58.333333%
  }

  .xl\:w-8\/12 {
    width: 66.666667%
  }

  .xl\:w-full {
    width: 100%
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:flex-row {
    flex-direction: row
  }

  .xl\:overflow-hidden {
    overflow: hidden
  }

  .xl\:bg-top {
    background-position: top
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem
  }

  .xl\:pr-0 {
    padding-right: 0
  }

  .xl\:pr-12 {
    padding-right: 3rem
  }

  .xl\:pr-16 {
    padding-right: 4rem
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.ltr\:-left-full:where([dir=ltr], [dir=ltr] *) {
  left: -100%
}

.ltr\:left-0:where([dir=ltr], [dir=ltr] *) {
  left: 0
}

.ltr\:left-auto:where([dir=ltr], [dir=ltr] *) {
  left: auto
}

.ltr\:right-0:where([dir=ltr], [dir=ltr] *) {
  right: 0
}

.ltr\:right-2:where([dir=ltr], [dir=ltr] *) {
  right: .5rem
}

.ltr\:right-4:where([dir=ltr], [dir=ltr] *) {
  right: 1rem
}

.ltr\:right-auto:where([dir=ltr], [dir=ltr] *) {
  right: auto
}

.ltr\:-mr-36:where([dir=ltr], [dir=ltr] *) {
  margin-right: -9rem
}

.ltr\:-mr-40:where([dir=ltr], [dir=ltr] *) {
  margin-right: -10rem
}

.ltr\:ml-2:where([dir=ltr], [dir=ltr] *) {
  margin-left: .5rem
}

.ltr\:ml-5:where([dir=ltr], [dir=ltr] *) {
  margin-left: 1.25rem
}

.ltr\:ml-auto:where([dir=ltr], [dir=ltr] *) {
  margin-left: auto
}

.ltr\:mr-0:where([dir=ltr], [dir=ltr] *) {
  margin-right: 0
}

.ltr\:mr-2:where([dir=ltr], [dir=ltr] *) {
  margin-right: .5rem
}

.ltr\:mr-4:where([dir=ltr], [dir=ltr] *) {
  margin-right: 1rem
}

.ltr\:mr-6:where([dir=ltr], [dir=ltr] *) {
  margin-right: 1.5rem
}

.ltr\:flex-row-reverse:where([dir=ltr], [dir=ltr] *) {
  flex-direction: row-reverse
}

.ltr\:rounded-r-md:where([dir=ltr], [dir=ltr] *) {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.ltr\:pl-6:where([dir=ltr], [dir=ltr] *) {
  padding-left: 1.5rem
}

.ltr\:pl-8:where([dir=ltr], [dir=ltr] *) {
  padding-left: 2rem
}

.ltr\:pr-4:where([dir=ltr], [dir=ltr] *) {
  padding-right: 1rem
}

.ltr\:text-left:where([dir=ltr], [dir=ltr] *) {
  text-align: left
}

@media (min-width: 768px) {
  .ltr\:md\:right-0:where([dir=ltr], [dir=ltr] *) {
    right: 0
  }
}

@media (min-width: 1024px) {
  .ltr\:lg\:-left-\[30\%\]:where([dir=ltr], [dir=ltr] *) {
    left: -30%
  }

  .ltr\:lg\:mr-\[-7rem\]:where([dir=ltr], [dir=ltr] *) {
    margin-right: -7rem
  }

  .ltr\:lg\:rounded-l-\[3rem\]:where([dir=ltr], [dir=ltr] *) {
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem
  }
}

.rtl\:-right-full:where([dir=rtl], [dir=rtl] *) {
  right: -100%
}

.rtl\:left-0:where([dir=rtl], [dir=rtl] *) {
  left: 0
}

.rtl\:left-2:where([dir=rtl], [dir=rtl] *) {
  left: .5rem
}

.rtl\:left-4:where([dir=rtl], [dir=rtl] *) {
  left: 1rem
}

.rtl\:left-auto:where([dir=rtl], [dir=rtl] *) {
  left: auto
}

.rtl\:right-0:where([dir=rtl], [dir=rtl] *) {
  right: 0
}

.rtl\:right-auto:where([dir=rtl], [dir=rtl] *) {
  right: auto
}

.rtl\:-ml-36:where([dir=rtl], [dir=rtl] *) {
  margin-left: -9rem
}

.rtl\:-ml-48:where([dir=rtl], [dir=rtl] *) {
  margin-left: -12rem
}

.rtl\:ml-0:where([dir=rtl], [dir=rtl] *) {
  margin-left: 0
}

.rtl\:ml-2:where([dir=rtl], [dir=rtl] *) {
  margin-left: .5rem
}

.rtl\:ml-4:where([dir=rtl], [dir=rtl] *) {
  margin-left: 1rem
}

.rtl\:ml-6:where([dir=rtl], [dir=rtl] *) {
  margin-left: 1.5rem
}

.rtl\:mr-2:where([dir=rtl], [dir=rtl] *) {
  margin-right: .5rem
}

.rtl\:mr-5:where([dir=rtl], [dir=rtl] *) {
  margin-right: 1.25rem
}

.rtl\:mr-auto:where([dir=rtl], [dir=rtl] *) {
  margin-right: auto
}

.rtl\:flex-col-reverse:where([dir=rtl], [dir=rtl] *) {
  flex-direction: column-reverse
}

.rtl\:space-x-reverse:where([dir=rtl], [dir=rtl] *)>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 1
}

.rtl\:rounded-l-md:where([dir=rtl], [dir=rtl] *) {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem
}

.rtl\:pl-4:where([dir=rtl], [dir=rtl] *) {
  padding-left: 1rem
}

.rtl\:pr-6:where([dir=rtl], [dir=rtl] *) {
  padding-right: 1.5rem
}

.rtl\:pr-8:where([dir=rtl], [dir=rtl] *) {
  padding-right: 2rem
}

.rtl\:text-right:where([dir=rtl], [dir=rtl] *) {
  text-align: right
}

.rtl\:text-sm:where([dir=rtl], [dir=rtl] *) {
  font-size: .875rem;
  line-height: 1.25rem
}

@media (min-width: 768px) {
  .rtl\:md\:left-0:where([dir=rtl], [dir=rtl] *) {
    left: 0
  }

  .rtl\:md\:text-right:where([dir=rtl], [dir=rtl] *) {
    text-align: right
  }
}

@media (min-width: 1024px) {
  .rtl\:lg\:-right-\[30\%\]:where([dir=rtl], [dir=rtl] *) {
    right: -30%
  }

  .rtl\:lg\:ml-\[-7rem\]:where([dir=rtl], [dir=rtl] *) {
    margin-left: -7rem
  }

  .rtl\:lg\:rounded-r-\[3rem\]:where([dir=rtl], [dir=rtl] *) {
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem
  }
}

textarea {
  height: 2.5rem;
  margin-top: 1rem;
  min-height: 2.5rem;
  max-height: -moz-max-content;
  max-height: max-content
}

.error-token {
  margin-bottom: 2rem
}

.error-token.right {
  text-align: right
}

@media (max-width: 575px) {
  .error-token.right {
    text-align: center
  }
}

.error-token-center {
  text-align: center;
  margin: 1rem 0
}

.custom-cluster img {
  display: none
}

.custom-cluster {
  display: flex;
  justify-self: center;
  align-items: center;
  position: relative
}

.custom-cluster div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto !important;
  width: 30px !important;
  height: 30px !important;
  line-height: normal !important;
  background: #fff;
  font-size: 16px !important;
  border-radius: 9999px
}

.custom-cluster div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #d4b071;
  border-radius: 50%;
  animation: pulse 2s infinite
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1
  }

  70% {
    transform: scale(1.2);
    opacity: .7
  }

  to {
    transform: scale(1);
    opacity: 1
  }
}

.gm-style-iw-d {
  max-width: 20rem
}

.gm-style .gm-style-iw-c,
.gm-style-iw-d {
  padding: 0 !important
}

.gm-style-iw-d {
  overflow: hidden !important
}

.gm-ui-hover-effect,
.gm-ui-hover-effect span {
  display: none !important
}

#OffPlan .completion-status,
#OffPlan .tags.flex.items-center .buy,
#OffPlan .tags.flex.items-center .rent {
  display: none
}

.agent-sticky {
  position: sticky;
  top: 10rem
}

.mainSlide .slick-list:before {
  display: none
}

.PropertyDetails footer {
  margin-top: 0;
  z-index: 10;
  position: relative
}

.imgAvatar img {
  transform: scale(2);
  transform-origin: top center
}

.mainPropertySlider .slick-list:before {
  display: none
}

.mainPropertySlider .slick-slider {
  height: 100%
}

.mainPropertySlider .slick-next {
  right: 1.5rem !important;
  background: transparent !important
}

.mainPropertySlider .slick-next:before {
  content: "";
  font-size: 3rem;
  background: url(https://cdn-icons-png.flaticon.com/128/271/271228.png);
  width: 3rem;
  background-size: cover;
  height: 3rem;
  background-position: 50%;
  position: absolute;
  top: 0;
  filter: invert(1);
  right: 1rem !important
}

.mainPropertySlider .slick-prev {
  left: 1.5rem;
  background: transparent !important
}

.mainPropertySlider .slick-prev:before {
  content: "" !important;
  font-size: 3rem;
  background: url(https://cdn-icons-png.flaticon.com/512/271/271220.png);
  width: 3rem;
  background-size: cover;
  height: 3rem;
  background-position: 50%;
  position: absolute;
  top: 0;
  filter: invert(1)
}

.mainPropertySlider .slick-dots {
  bottom: 5.5rem
}

.mainPropertySlider .slick-dots li button:before {
  opacity: 1;
  color: #fff;
  width: 1rem;
  height: 1rem;
  font-size: 1rem
}

.mainPropertySlider .slick-dots li.slick-active button:before {
  color: #cdac62
}

.alsoHave .slick-list:before {
  display: none
}

[dir=rtl] .mainPropertySlider .slick-next {
  left: auto !important
}

[dir=rtl] .mainPropertySlider .slick-prev {
  left: 25px !important
}

@media only screen and (max-width: 767px) {
  .mainPropertySlider .slick-dots {
    bottom: 9.5rem
  }
}

@media only screen and (max-width: 1280px) {
  .mainPropertySlider .slick-dots {
    bottom: 6.5rem
  }
}

.scrollable-container::-webkit-scrollbar {
  width: 0
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: transparent
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 3px
}

.height3 {
  height: 46px
}

.whatsapp {
  z-index: 999999
}

main,
main.active {
  transition: all 1s ease-in-out
}

.authBtns .btn {
  transition: all .2s ease-in-out;
  left: -.4rem
}

.authBtns:hover .btn {
  top: 3.5rem;
  opacity: 1
}

.langBtns .btn {
  transition: all .2s ease-in-out
}

.langBtns:hover .btn {
  top: 3.5rem;
  opacity: 1
}

.activeNav {
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 0;
  transition: all .2s ease-in-out;
  z-index: 40
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important
}

.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
  background-color: transparent I !important
}

.react-tel-input .flag-dropdown {
  top: 0 !important
}

.form-control {
  height: 100%;
  width: 100%
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 100% !important
}

.mySwiperCarasole .swiper-button-next:after {
  color: #333;
  right: -35%;
  position: absolute;
  overflow: visible !important
}

.mySwiperCarasole .swiper-button-prev:after {
  color: #333;
  left: -35%;
  position: absolute
}

.swiper-button-next,
.swiper-button-prev {
  color: transparent !important
}

.mySwiperCarasole {
  padding: 0 20px
}

.range2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: #d4b071
}

.range2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #d4b071;
  border-radius: 50%;
  border: none;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out
}

.range2::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #d4b071;
  border-radius: 50%;
  border: none;
  -moz-transition: .2s ease-in-out;
  transition: .2s ease-in-out
}

.range2::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px hsla(38, 54%, 64%, .2)
}

.range2:active::-webkit-slider-thumb,
.range2:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px hsla(38, 54%, 64%, .2)
}

.range2::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px hsla(38, 54%, 64%, .2)
}

.range2:active::-moz-range-thumb,
.range2:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px hsla(38, 54%, 64%, .2)
}

.react-tel-input .flag-dropdown.open {
  background: transparent !important
}

.swiper-slide {
  align-items: center
}

.swiper {
  margin-left: auto;
  margin-right: auto
}

.swiper-slide {
  background-size: cover;
  background-position: 50%
}

.mySwiper2 {
  height: 80%;
  width: 100%
}

.mySwiperThumb {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0
}

.mySwiperThumb .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: .4
}

.mySwiper2 div div img {
  border-radius: 3rem
}

.mySwiperThumb div div img {
  border-radius: 2rem
}

@media (max-width: 640px) {
  .mySwiperThumb div div img {
    border-radius: .7rem
  }

  .mySwiper2 div div img {
    border: 2px solid;
    border-radius: 1rem
  }
}

.mySwiperThumb .swiper-slide-thumb-active {
  opacity: 1
}

.Banner {
  height: 100vh
}

.Banner .Slider,
.Banner .Slider img,
.Banner .slick-list,
.Banner .slick-list img,
.Banner .slick-slider,
.Banner .slick-slider img,
.Banner .slick-track,
.Banner .slick-track img {
  height: calc(100vh - 8rem)
}

.Banner .mainBanner {
  transition: all 1.5s ease-in-out
}

.Banner .mainBanner.closeBanner {
  transform: scale(0) translateX(200%);
  z-index: -9;
  transition: all 1.5s ease-in-out
}

.Banner .Slider {
  transition: all 1.5s ease-in-out;
  transform: scale(.1);
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%
}

.Banner .Slider img {
  width: 100%
}

.Banner .Slider.openSlider {
  z-index: 0;
  transform: none;
  transition: all 1s ease-in-out;
  left: 0
}

.Banner .SlideIsClosed {
  border: 3px solid #cdac62;
  border-radius: .5rem
}

.Banner .SlideIsClosed * {
  height: 5rem;
  width: 100%;
  position: relative;
  overflow: hidden
}

.Banner .slick-list:before {
  display: none
}

.Banner .Apartment {
  width: 50rem
}

.Banner .slick-next,
.Banner .slick-prev {
  right: calc(50% - 2rem);
  top: calc(50% - 6.5rem);
  z-index: 55;
  border-radius: 0 .5rem .5rem 0 !important
}

.Banner .slick-prev {
  left: calc(50% - 2rem);
  border-radius: .5rem 0 0 .5rem !important
}

.Banner button.slick-disabled {
  background: #fff !important
}

.Banner button.slick-disabled:before {
  color: #cdac62 !important
}

@media screen and (max-width: 768px) {
  .Banner .Apartment {
    width: 100%
  }

  .Banner .slick-next,
  .Banner .slick-prev {
    top: calc(45% - 6.5rem)
  }
}


.lottiText,
.lottiText svg {
  border-radius: .5rem
}

.lottiText svg {
  height: auto !important;
  width: 75% !important;
  margin: 0 auto;
  transition: all 1s ease-in-out
}

.lottiText svg:hover {
  filter: brightness(1.3) contrast(1.3) grayscale(1) drop-shadow(2px 4px 6px black);
  transform: translateZ(0) scale(.9) !important;
  background: transparent
}

.lottiText div {
  cursor: default !important
}

.text-red {
  text-align: left
}

.drop-shadow-md {
  filter: unset;
  box-shadow: 1px 4px 3px 1px #00000012
}

#DeveloperSwiperImg {
  padding: 0 2rem;
  -o-object-fit: contain;
  object-fit: contain;
  aspect-ratio: 3/2;
  mix-blend-mode: color-burn
}

.aminities.AmenitiesActive {
  overflow-y: auto;
  height: 10rem;
  z-index: 999
}

.aminitiesCheckbox .checkmark:after {
  left: 5px;
  top: 1.2px;
  width: 5px;
  height: 11px
}

.bedRoom .checkBox input:checked~.checkmark:after {
  display: none
}

.quiz-container {
  width: 100%;
  min-height: 40vh;
  margin-left: auto;
  margin-right: auto
}

.active {
  display: block
}

.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
  background-color: transparent
}

.next-prev {
  border-radius: 30px;
  border: 1px solid transparent;
  height: 40px;
  padding: 0 40px;
  font-size: 17px;
  cursor: pointer;
  transition: all .1s ease
}

.slide-in-right {
  animation: slideInRight .3s forwards
}

.slide-out-left {
  animation: slideOutLeft .3s forwards
}

@keyframes slideInRight {
  0% {
    transform: translateX(10%)
  }

  to {
    transform: translateX(0)
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0)
  }

  to {
    transform: translateX(-10%)
  }
}

.active_input:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  top: 7px;
  right: 7px;
  background-color: var(--primary-color)
}

.block-div {
  display: block
}

.block-div.end {
  display: contents
}

@media(max-width: 600px) {
  .grid.myGrid {
    display: block
  }

  .block-div {
    margin-bottom: 1rem
  }

  .block-div.end {
    display: block
  }
}

.file-div,
.file-name {
  width: 220px
}

.file-name {
  text-align: left;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

@media(max-width: 575px) {
  .quiz-container {
    margin-top: 6rem
  }

  .aspect-\[18\/9\] {
    aspect-ratio: unset
  }
}

.block-feild {
  display: block;
  width: 33.33%
}

.block-feild.end {
  margin-bottom: 2rem;
  width: 100%
}

.block-feild textarea {
  margin-bottom: 0 !important
}

@media(max-width: 767px) {
  .block-feild {
    width: 100%
  }
}

.swiper {
  height: 100%
}

.swiper-slide {
  background: #fff
}

.swiper-slide img {
  height: 100%
}

.swiper {
  width: 100%
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center
}

.swiper-slide img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
  font-size: 25px
}

.swiper-pagination-bullet-active {
  background-color: #fff !important
}

.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-prev {
  background-color: #fff !important;
  opacity: 1 !important
}

.mySwiperAmenities img {
  border-radius: 3rem
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.playContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%
}

.button {
  display: inline-block;
  position: relative
}

.button.is-play {
  background-color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px
}

.button.is-play .button-outer-circle {
  background: hsla(0, 0%, 100%, .2);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%
}

.button.is-play .button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%)
}

.button.is-play .button-icon .triangle {
  animation: fadeIn 7s ease
}

.button.is-play .button-icon .path {
  stroke-dasharray: 90;
  stroke-dashoffset: 0;
  animation: triangleStroke 1s;
  animation-timing-function: ease
}

.has-scale-animation {
  animation: smallScale 2s infinite
}

.has-delay-short {
  animation-delay: .5s
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes triangleStroke {
  0% {
    stroke-dashoffset: 90
  }

  to {
    stroke-dashoffset: 0
  }
}

@keyframes smallScale {
  0% {
    transform: scale(1);
    opacity: 1
  }

  to {
    transform: scale(2.5);
    opacity: 0
  }
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity .25s 0s, transform .25s
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear .25s, opacity .25s 0s, transform .25s
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1rem 1.5rem;
  width: 24rem;
  border-radius: .5rem
}

textarea::-moz-placeholder {
  margin-top: 20px
}

textarea::placeholder {
  margin-top: 20px
}

.react-tel-input .flag-dropdown {
  border: none !important;
  background-color: transparent !important
}

.react-tel-input .form-control {
  border: none !important;
  width: auto;
}

.auth {
  min-height: 70vh
}

.or:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  background: #cdac62;
  height: 1px;
  top: 50%
}

input {
  border: 0;
}


/*-----------------	
    30. Contact
-----------------------*/
.contact-section {
  background: #F7F6FF;
  padding: 80px 0 56px;
}

@media (max-width: 991.98px) {
  .contact-section {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .contact-section {
    padding: 40px 0 16px;
  }
}

.contact-section .contact-content .card {
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  border: 0;
  border-radius: 0;
  margin: 0 0 24px;
}

.contact-section .contact-content .card .contact-info h3 {
  font-weight: 600;
  margin: 0 0 16px;
}

@media (max-width: 767.78px) {
  .contact-section .contact-content .card .contact-info h3 {
    font-size: 20px;
  }
}

.contact-section .contact-content .card .contact-info p {
  margin: 0 0 15px;
}

.contact-section .contact-content .card img {
  max-width: 636px;
  border-radius: 10px;
}

.contact-section img {
  border-radius: 10px;
  margin-bottom: 24px;
}

.contact-info-sec {
  padding: 80px 0 56px;
}

@media (max-width: 991.98px) {
  .contact-info-sec {
    padding: 50px 0 26px;
  }
}

@media (max-width: 767.78px) {
  .contact-info-sec {
    padding: 40px 0 16px;
  }
}

.contact-info-sec label {
  font-size: 14px;
  font-weight: 500;
}

.contact-info-sec h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 40px;
}

@media (max-width: 991.98px) {
  .contact-info-sec h3 {
    font-size: 26px;
    margin: 0 0 30px;
  }
}

@media (max-width: 767.78px) {
  .contact-info-sec h3 {
    font-size: 22px;
    margin: 0 0 20px;
  }
}

.contact-info-sec .btn-primary {
  padding: 13px 15px;
}

@media (max-width: 767.78px) {
  .contact-info-sec .btn-primary {
    padding: 10px 15px;
  }
}

.contact-info-sec .card {
  border: 1px solid #f4f4f4;
  border-radius: 0;
}

.contact-info-sec .card .card-header {
  padding-bottom: 0;
}

.contact-info-sec .card .card-header h3 {
  font-weight: 600;
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
  padding: 0 0 20px;
}

.contact-info-sec .card .card-body {
  padding-top: 20px;
}

.contact-info-sec .contact-info-details {
  margin: 0 0 37px;
}

.contact-info-sec .contact-info-details span {
  /* border: 1px solid #f4f4f4; */
  /* border-radius: 10px; */
  margin: 0 8px 0 0;
  /* height: 72px; */
  /* width: 72px; */
}

.contact-info-sec .contact-info-details h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
}

@media (max-width: 991.98px) {
  .contact-info-sec .contact-info-details h4 {
    font-size: 18px;
  }
}

.contact-info-sec .contact-info-details a {
  color: #8A909A;
}

.contact-info-sec .contact-info-details p {
  max-width: 254px;
}

@media (max-width: 767.78px) {
  .contact-info-sec .contact-info-details {
    margin: 0 0 24px;
  }
}

.map-location {
  margin-bottom: 24px;
}

.map-location iframe {
  width: 100%;
}

.sellPropertyBg {
  background: url('https://backend.keymaxrealestate.com/storage/services/2023/08/25/64e8a6fa63e1f.png') center no-repeat;
  background-size: cover;
}

.sellBg {
  background-image: linear-gradient(to right, rgba(14, 14, 14, 0.77), rgba(0, 0, 0, 0) 60%), url('https://backend.keymaxrealestate.com/storage/services/2023/10/02/651a67e061224.jpeg');
}

.rentBg {
  background-image: linear-gradient(to right, rgba(14, 14, 14, 0.77), rgba(0, 0, 0, 0) 60%), url('https://backend.keymaxrealestate.com/storage/services/2023/10/02/651a67cfbe845.jpeg');
}

.rentPropertyBg {
  background: url('https://backend.keymaxrealestate.com/storage/services/2023/08/25/64e8a6d868d65.webp') center no-repeat;
  background-size: cover;
}

.buyBg {
  background-image: linear-gradient(to right, rgba(14, 14, 14, 0.77), rgba(0, 0, 0, 0) 60%), url('https://backend.keymaxrealestate.com/storage/services/2023/10/02/651a67eedd148.jpeg');
}

.buyPropertyBg {
  background: url('https://backend.keymaxrealestate.com/storage/services/2023/08/25/64e8a6fa63e1f.png') center no-repeat;
  background-size: cover;
}

.slick-next {
  right: 5px !important;
  background: none !important;
}

.slick-prev {
  left: 0 !important;
  background: none !important;
}

.propertyCardSpan {
  margin-left: 8px;
  color: #000000;
}

.devSubDetails {
  font-size: 18px;
  font-weight: 700;
}

.slick-list:before {
  width: 0 !important;
}

figure {
  margin-bottom: 0 !important;
}

.iconUp {
  display: none;
}

.subMenuOpen:hover .iconDown {
  display: none;
}

.subMenuOpen:hover .iconUp {
  display: inline-block;
}

.headingBorder {
  border-bottom: 2px solid #d4b071;
}

.areaBg {
  background: url('https://backend.keymaxrealestate.com/storage/areas/2023/10/03/business-bay-1.webp') no-repeat center;
  background-size: cover;
}

.block-feild>input,
.react-tel-input {
  border-bottom: 1px solid black;
}

.block-feild>textarea {
  border: none;
  border-bottom: 1px solid black !important;
}

.w-full>input {
  border-bottom: 1px solid black;
}

.w-full:last-child>input {
  border-bottom: 0;
}

.applyOuter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.applyOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* Adjust the z-index to make the overlay appear above other elements */
}

.applyForm {
  z-index: 2;
  /* Ensure the form appears above the overlay */
  background: white;
  padding: 20px;
  border-radius: 5px;
}

.offcanvas {
  left: 50% !important;
  transform: translate(-50%, 0px) !important;
  width: 40% !important;
}

.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.page-link.active,
.active>.page-link {
  background-color: #ebbb67 !important;
  border-color: #ebbb67 !important;
}

.page-link {
  color: #000 !important;
}

.submenu {
  display: none;
  /* Hide the submenu by default */
}

.show-submenu {
  display: block;
  /* Show the submenu when the show-submenu class is applied */
  /* Add any additional styling as needed */
}

@media only screen and (max-width: 767px) {
  .submenu {
    display: none;
    /* Hide the submenu by default on smaller screens */
  }

  .show-submenu .submenu {
    display: block;
    /* Show the submenu when its parent menu item is toggled */
  }

  .show-submenu .guidesubmenu {
    display: block;
    /* Show the submenu when its parent menu item is toggled */
  }
}

.planCard {
  background-color: #fff;
  border-radius: 20px;
  border: 5px solid #ebbb67;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #ebbb67 !important;
}
.accordion-button{
  color: #ebbb67 !important;

}

.headingTextColor{
  color: #ebbb67 !important;
}
.textWhite{
  color: #fff !important;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.white-text p {
  color: white;
}

.upperCase{
  text-transform: uppercase !important;
}